import axios from 'axios';
import {useSessionStore} from '@/stores/session'
import {alertController} from '@ionic/vue';

export default function setup() {

  axios.defaults.baseURL = process.env.VUE_APP_MW_HOST;
  axios.defaults.timeout = 15000;

  axios.interceptors.request.use((config) => {
    const sessionStore = useSessionStore();
    if (sessionStore.getSessionId) {
      config.headers.sessionId = sessionStore.getSessionId;
    }
    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {

      const sessionStore = useSessionStore();
      const originalConfig = error.config;
      if (error.response?.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        sessionStore.logOut(false).finally(() => {
          sessionStore.setErrorMessage('Login scaduto, inserire nuovamente i dati');
          window.location.href = '/login';
        });
      }
      else if([503].includes(error.response?.status)){
        window.location.href = '/errore/server-in-manutenzione';
      }
      else if ([500].includes(error.response?.status)) {
        const alert = await alertController.create({
          message: '<i class="icon sgri-close"></i> ' +
            '<h3 class="title">Ops! Qualcosa è andato storto.</h3> ' +
            '<div class="message">Se il problema persiste, contattare il servizio clienti.</div>',
          buttons: [
            {
              text: 'Ricarica la pagina',
              role: 'confirm',
              handler: () => {
                //location.reload();
              }
            },
            {
              text: 'x',
              role: 'cancel',
              cssClass: 'alert-button-cancel',
            },
          ],
          cssClass: 'errorPopup danger',
          backdropDismiss: false,
        });

        await alert.present();
      }
      else if ([404].includes(error.response?.status)) {
        const alert = await alertController.create({
          message: '<i class="icon sgri-close"></i> ' +
            '<h3 class="title">Pagina non trovata</h3> ' +
            '<div class="message"></div>',
          buttons: [
            {
              text: 'Chiudi',
              role: 'confirm',
              handler: () => {
                //location.reload();
              }
            },
            {
              text: 'x',
              role: 'cancel',
              cssClass: 'alert-button-cancel',
            },
          ],
          cssClass: 'errorPopup danger',
          backdropDismiss: false,
        });

        await alert.present();
      }
      else if ([0].includes(error.response?.status)) {
        /*
        const alert = await alertController.create({
          message: '<i class="icon sgri-close"></i> ' +
          '<h3 class="title">Ops! Qualcosa è andato storto.</h3> ' +
          '<div class="message">Se il problema persiste, contattare il servizio clienti.</div>',
          buttons: [
            {
              text: 'Ricarica la pagina',
              role: 'confirm',
              handler: () => {
                location.reload();
              }
            },
            {
              text: 'x',
              role: 'cancel',
              cssClass: 'alert-button-cancel',
            },
          ],
          cssClass: 'errorPopup danger',
          backdropDismiss: false,
        });
         */
        //await alert.present();
      }
      return Promise.reject(error);
    }
  );
}
