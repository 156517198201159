<template>
  <ion-modal :is-open="modalContactFormOpen" :backdrop-dismiss="false">
    <ion-header class="ion-no-border">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button @click="close" fill="clear" size="small" slot="end" shape="round">
            <i slot="icon-only" class="sgri-close fs-4 text-dark-shade"/>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <h2 class="fs-5 fw-bold mb-4 mt-0">{{ $t('modal.contact_form.title') }}</h2>
      <div class="mb-5" v-t="{path:'modal.contact_form.description', args:{customer_name: customerName }}"></div>
      <ion-grid>
        <!--
        <ion-row>
          <ion-col size="12">
            <div class="fs-base mb-2">
              {{ $t('modal.contact_form.form.label.email') }}
            </div>
            <div class="mb-6">
              <ion-item>
                <ion-input type="email" helper-text="Inserisci email"
                           error-text="Invalid email" :aria-label="$t('modal.contact_form.form.label.email')" v-model="data.email" :placeholder="$t('modal.contact_form.form.placeholder.email')" />
                <error-messages class="errors" v-if="errorsControl.email" :errors="errorsControl.email"/>
              </ion-item>
            </div>
          </ion-col>
        </ion-row>
        // -->
        <ion-row>
          <ion-col size="12">
            <div class="fs-base mb-2">
              {{ $t('modal.contact_form.form.label.contract_key') }}
            </div>
            <div class="mb-6">
              <ion-item>
                <ion-select v-model="data.contract.key" interface="popover" required aria-label="oggetto richiesta" :placeholder="$t('modal.contact_form.form.placeholder.contract_key')">
                  <ion-select-option v-for="contract in contracts" :key="contract.key" :data-value="contract.key" :value="contract.key">{{ parseInt(contract.key) }}</ion-select-option>
                </ion-select>
                <error-messages class="errors" v-if="errorsControl.contract_key" :errors="errorsControl.contract_key"/>
              </ion-item>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <div class="fs-base mb-2">
              {{ $t('modal.contact_form.form.label.phone') }}
            </div>
            <div class="mb-6">
              <ion-item>
                <ion-input type="text" helper-text="Inserisci telefono"
                           error-text="Invalid phone" :aria-label="$t('modal.contact_form.form.label.phone')" v-model="data.mobilephone" :placeholder="$t('modal.contact_form.form.placeholder.phone')" />
                <error-messages class="errors" v-if="errorsControl.mobile" :errors="errorsControl.mobile"/>
              </ion-item>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <div class="fs-base mb-2">
              {{ $t('modal.contact_form.form.label.subject') }}
            </div>
            <div class="mb-6">
              <ion-item>
                <ion-select v-model="data.subject" interface="popover" required aria-label="oggetto richiesta" :placeholder="$t('modal.contact_form.form.placeholder.subject')">
                  <ion-select-option data-value="test" value="gestione contratto">Gestione contratto</ion-select-option>
                  <ion-select-option value="pagamento">Pagamenti</ion-select-option>
                  <ion-select-option value="bolletta">Bolletta</ion-select-option>
                  <ion-select-option value="contatore">Contatore</ion-select-option>
                  <ion-select-option value="nuova offerta">Nuova offerta</ion-select-option>
                  <ion-select-option value="autolettura">Autolettura</ion-select-option>
                  <ion-select-option value="altro">Altro</ion-select-option>
                </ion-select>
                <error-messages class="errors" v-if="errorsControl.subject" :errors="errorsControl.subject"/>
              </ion-item>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <div class="fs-base mb-2">
              {{ $t('modal.contact_form.form.label.message') }}
            </div>
            <div class="mb-6">
              <ion-item>
                <ion-textarea @input="data.description=$event.target.value" :auto-grow="true" :aria-label="$t('modal.contact_form.form.label.message')" required :placeholder="$t('modal.contact_form.form.placeholder.message')"></ion-textarea>
                <error-messages class="errors"  v-if="errorsControl.description" :errors="errorsControl.description"/>
              </ion-item>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <p class="fs-9 fw-medium text-dark-shade">Con l’inserimento dei dati sopra richiesti, di autorizza
              il trattamento degli stessi da parte di Gruppo SGR per la fornitura
              del servizio di assistenza in accordo a quanto previsto dalla normativa
              applicabile in materia di protezione dei dati personali.<br>
              Per ulteriori informazioni consulta la <a :href="data.company == 'SGR' ? 'https://www.sgrlucegas.com/privacy-policy/' : 'https://www.asteaenergia.it/privacy-policy/'" target="_blank">privacy policy</a></p>
          </ion-col>
        </ion-row>


        <div class="ion-text-center" v-if="!isMobile">
          <ion-button color="primary" @click="send">
            <span v-if="!loading" v-t="{path:'modal.contact_form.form.label.submit'}"/>
            <ion-spinner v-if="loading" name="crescent" slot="end" />
          </ion-button>
        </div>
      </ion-grid>
    </ion-content>
    <ion-footer v-if="isMobile">
      <ion-button color="primary" @click="send">
        <span v-if="!loading" v-t="{path:'modal.contact_form.form.label.submit'}"/>
        <ion-spinner v-if="loading" name="crescent" slot="end" />
      </ion-button>
    </ion-footer>
  </ion-modal>
</template>
<script>
import {computed, defineComponent, ref} from 'vue';
import {
  IonButtons, IonCol, IonContent, IonSpinner, IonSelect, IonSelectOption, IonGrid, IonHeader, IonInput, IonItem, IonRow, IonToolbar, IonTextarea
} from '@ionic/vue';

import {useI18n} from "vue-i18n";
import {useSessionStore} from "@/stores/session";
import {useContractsStore} from "@/stores/contracts";
import ErrorMessages from "@/components/errors/ErrorMessagesComponent.vue";
import {useRoute} from "vue-router";

export default defineComponent({
  name: 'FloatHelper',
  components: {
    ErrorMessages, IonSpinner,
    IonItem, IonCol, IonGrid, IonRow, IonInput,
    IonToolbar, IonHeader, IonContent,  IonButtons,
    IonSelect, IonSelectOption, IonTextarea
  },
  data() {
    return {
      customerName: '',
      feedbackPayload: {
        title: '',
        description: '',
        firstButton: {
          label: '',
          link: '',
        },
      },
      loading: false,
      errorMessage: null,
      submitDisabled: false,
      submitted: false
    }
  },
  setup() {
    const {t} = useI18n();
    const sessionStore = useSessionStore();
    const contractsStore = useContractsStore();
    const route = useRoute();

    const company = computed(() => sessionStore.getCompany);
    const modalContactFormOpen = ref(true);

    const contracts = ref([]);
    const data = ref({
      //email: '',
      customer: {
        key: ''
      },
      contract: {
        key: ''
      },
      subject: '',
      description: '',
      mobilephone: ''
    });

    const formSelect = ref(null);

    return { t, company, sessionStore, contractsStore, modalContactFormOpen, data, formSelect, route, contracts };
  },
  computed: {
    isMobile() {
      return window.innerWidth < 992
    },
    errorsControl() {
      const errors = { /*email: false,*/ description: false, subject: false, mobile: false, contract_key: false };

      if(this.submitted)
      {
        //let filter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        //if (!filter.test(this.data.email)) errors.email = this.t('modal.contact_form.form.errors.email');
        if(!this.data.subject) errors.subject = this.t('modal.contact_form.form.errors.subject');
        if(!this.data.description) errors.description = this.t('modal.contact_form.form.errors.message');
        if(!this.data.mobilephone) errors.mobile = this.t('modal.contact_form.form.errors.phone');
        if(!this.data.contract.key) errors.contract_key = this.t('modal.contact_form.form.errors.contract_key');
      }

      return errors;
    },
    customerId() {
      return this.contractsStore.getCustomerId;
    }
  },
  mounted() {
    this.data.company = this.sessionStore.company.toUpperCase();
    if(this.contractsStore.customer)
    {
      this.contractsStore.getContracts().then((res) => {
        if (res.success) {
          this.contracts = res.data;
        }
      });

      this.data.mobilephone = this.contractsStore.customer?.account?.phoneMobile;
      this.data.customer.key = this.contractsStore.customer.key;
      var firstname = this.contractsStore.customer.account.name.split(/\s+/);
      this.customerName = this.capitalizeFirstLetter( firstname[firstname.length-1].toLowerCase() );
    }

  },
  watch: {
    customerId() {
      var firstname = '';
      if(this.contractsStore.customer)
      {
        this.contractsStore.getContracts().then((res) => {
          if (res.success) {
            this.contracts = res.data;
          }
        });
        this.data.mobilephone = this.contractsStore.customer?.account?.phoneMobile;
        //this.data.email = this.contractsStore.customer?.account?.email;
        this.data.customer.key = this.contractsStore.customer.key;
        firstname = this.contractsStore.customer.account.name.split(/\s+/);
        this.customerName = this.capitalizeFirstLetter( firstname[firstname.length-1].toLowerCase() );
      }
    },
  },
  methods: {
    close(){
      this.$emit('close');
    },
    capitalizeFirstLetter(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    },
    send() {
      const self = this;
      this.submitted = true;
      const hasNotErrors = Object.values(this.errorsControl).every(value => value === false);

      if(hasNotErrors)
      {

        if(!this.data.customer.key) delete this.data.customer;
        if(!this.data.contract.key) delete this.data.contract;

        self.loading = true;
        self.contractsStore.postRequestInformation(self.data).then((res) => {
          if (res.success) {
            self.sessionStore.setFeedbackData({
              title:self.t('modal.contact_form.form.feedback.success.title'),
              description:self.t('modal.contact_form.form.feedback.success.description'),
              firstButton: {label: self.t('feedback.buttons.close'), close: true},
            });
            this.$emit('sended');
          } else self.$helpers.showErrorMessage(res.errors);
        })
            .catch((error) => self.$helpers.manageCatchError(error))
            .finally(() => self.loading = false)
      }

    },
  },
});
</script>

<style scoped lang="scss">
.errors {
  position: absolute;
  right: 15px;
}
@media screen and (max-width: 568px) {
  .errors {
    top: -16px;
    right: auto;
  }
}
ion-modal {
  --height: 100vh;
  --width: 100vw;
}
ion-footer {
  background: var(--ion-color-primary);
  text-align: center;
  ion-button {
    width: 100%;
  }
}
ion-fab-list {
  right: 0;

  ion-fab-button {
    width: 220px;
    height: 48px;

    &::part(native) {
      border-radius: 40px;
    }

    .inner {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
      margin-right: auto;
      padding: 8px 16px;
    }
  }
}
</style>