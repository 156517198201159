import {defineStore} from 'pinia'
import axios from "axios"
import moment from "moment"
import _cloneDeep from "lodash/cloneDeep";
import { Storage } from '@ionic/storage';
import uninterceptedAxiosInstance from "@/services/setupUninterceptors";
import {useSessionStore} from '@/stores/session'

const getDefaultCustomerId = () => {
  const val = localStorage.getItem('_cId');
  return val ? val : null;
};

const storageName = 'contracts';
const storage = new Storage({
  storeName: storageName
});
storage.create();

const cipher = salt => {
  const textToChars = text => text.split('').map(c => c.charCodeAt(0));
  const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
  const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

  return text => text.split('')
    .map(textToChars)
    .map(applySaltToChar)
    .map(byteHex)
    .join('');
}
const decipher = salt => {
  const textToChars = text => text.split('').map(c => c.charCodeAt(0));
  const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
  return encoded => encoded.match(/.{1,2}/g)
    .map(hex => parseInt(hex, 16))
    .map(applySaltToChar)
    .map(charCode => String.fromCharCode(charCode))
    .join('');
}

export const useContractsStore = defineStore('contracts', {
  state: () => {
    return {
      contracts: [],
      supplies: [],
      customer: null,
      customerId: getDefaultCustomerId(),
      showLegend: false,
      showGraph: false,

      registerData: {
        contract: null,
      },
    }
  },
  getters: {
    getCustomerId: (state) => state.customerId,
    getCurrentCustomer: (state) => state.customer,
    getCurrentSupplies: (state) => state.supplies,
    getCustomerIdByContract: (state) => {
      return (contractId) => {
        const contract = state.contracts.find((contract) => contract.id === contractId);
        return contract?.customerId;
      }
    },
    getRegisterContract: (state) => state.registerData.contract,
  },
  actions: {
    clearStorage(){
      storage.clear();
    },
    async getContracts(pagingTake = 100, pagingSkip = 0) {
      const params = {
        pagingTake,
        pagingSkip,
        customerKey: this.customer.key
      };
      try {
        this.contracts = [];
        let contracts = [];
        const {data} = await axios.get('/services/rest/portalapi/Erp/v1/contracts', {params});
        if (typeof data.findContractsResponse.contracts.contract !== "undefined")
          contracts = data.findContractsResponse.contracts.contract;
        if (!Array.isArray(contracts)) contracts = [contracts];
        this.contracts = _cloneDeep(contracts);

        return {success: true, data: contracts};
      } catch (error) {
        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async getContract(id, filters = {}) {
      let params = {};
      params = {...params, ...filters};
      try {
        let contract = null;
        const {data} = await axios.get('/services/rest/portalapi/Erp/v1/contracts/' + id, {params});
        if (typeof data.getContractResponse.contract !== "undefined") contract = data.getContractResponse.contract;

        return {success: true, data: contract};
      } catch (error) {
        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async getOffer(id, filters = {}) {
      let params = {
        useExtKey: true,
      };
      params = {...params, ...filters};
      try {
        let offer = null;
        const {data} = await axios.get('/services/rest/portalapi/Erp/v1/offers/' + id, {params});
        if (typeof data.getOfferResponse.offer !== "undefined")
          offer = data.getOfferResponse.offer;

        return {success: true, data: offer};
      } catch (error) {
        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async setRegisterContract(contract) {
      this.registerData.contract = _cloneDeep(contract);
      return this.registerData.contract;
    },


    async getSupplies(filters = {}, pagingTake = 1000, pagingSkip = 0, activeOnly = true) {
      let params = {
        pagingTake,
        pagingSkip,
        activeOnly,
      };
      if (typeof filters.customerKey === "undefined" && typeof this.customer !== 'undefined' && this.customer) params.customerKey = this.customer.key;
      params = {...params, ...filters};
      try {
        this.supplies = [];
        let supplies = [];
        const {data} = await axios.get('/services/rest/portalapi/Erp/v1/supplies', {params});
        if (typeof data.findSuppliesResponse.supplies.supply !== "undefined")
          supplies = data.findSuppliesResponse.supplies.supply;

        if (!Array.isArray(supplies)) supplies = [supplies];

        /** Filtro temporaneo per ottenere i servizi reali dell'utente loggato **/
        //supplies = supplies.filter((supply) => this.contracts.some((contract) => contract.id === supply.contractId))

        this.supplies = _cloneDeep(supplies);
        return {success: true, data: supplies};
      } catch (error) {
        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async getSupply(id, technicalData = false, useExtKey = false, cached = false) {
      const myCipherK = 'getSupply' + id + technicalData + useExtKey;
      const myCipherR = 'getSupplyR' + id + technicalData + useExtKey;
      const myCipher = cipher(myCipherK);
      if(cached)
      {
        var v = await storage.get(myCipher(storageName));
        if(v !== null){
          const myCipherD = decipher(myCipherR);
          return JSON.parse(myCipherD(v));
        }
      }

      try {
        var params = {
          includeTechnicalData: technicalData
        };

        if (useExtKey) {
          params.useExtKey = true;
        }

        let supply = null;
        const {data} = await axios.get('/services/rest/portalapi/Erp/v1/supplies/' + id, {params});
        if (typeof data.getSupplyResponse.supply !== "undefined")
        {
          supply = data.getSupplyResponse.supply;
        }

        const _return = {success: true, data: supply}
        return _return;
      } catch (error) {
        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async postSupplyNickname(id, nickname) {
      try {
        const params = {
          modifySupplyRequest: {
            supply: {
              id,
              nickname
            }
          }
        };
        await axios.put('/services/rest/portalapi/Erp/v1/supplies', params);
        return {success: true};
      } catch (error) {
        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async updateProposal(id, actionSelected) {
      try {
        const params = {
          modifyProposalRequest: {
            proposal: {
              id,
              actionSelected
            }
          }
        };
        await uninterceptedAxiosInstance.put('/services/rest/portalapi/Erp/v1/proposals', params);
        return {success: true};
      } catch (error) {
        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async getInvoices(filters, orderBy = 'Desc', pagingTake = 5, pagingSkip = 0, cached = false) {
      const k = 'getInvoices' + JSON.stringify(filters) + '_' + orderBy + '_'+ + pagingTake + '_' + pagingSkip;
      const myCipherK = k;
      const myCipherR = k + 'R';
      const myCipher = cipher(myCipherK);
      if(cached)
      {
        var v = await storage.get(myCipher(storageName));
        if(v !== null){
          const myCipherD = decipher(myCipherR);
          return JSON.parse(myCipherD(v));
        }
      }

      let invoices = [];
      let params = {
        pagingSkip,
        pagingTake,
        orderBy,
        customerId: this.customer.id
      };
      params = {...params, ...filters};
      try {
        const {data} = await axios.get('/services/rest/portalapi/Erp/v1/invoices', {params});
        if (typeof data.findInvoicesResponse.invoices.invoice !== "undefined")
          invoices = data.findInvoicesResponse.invoices.invoice;

        if (!Array.isArray(invoices)) invoices = [invoices];
        const _return = {success: true, data: invoices, count: data.findInvoicesResponse.count};
        const myCipher2 = cipher(myCipherR);
        storage.set(myCipher(storageName), myCipher2(JSON.stringify(_return)));
        return _return;
      } catch (error) {
        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async checkInvoicesPaid(filters = {}) {
      let params = {
        pagingSkip: 0,
        pagingTake: 100,
        outstandingOnly: true,
        customerId: this.customer.id
      };
      params = {...params, ...filters};
      try {
        const {data} = await axios.get('/services/rest/portalapi/Erp/v1/invoices', {params});
        return {success: true, data: data.findInvoicesResponse.count === 0};
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async getInvoice(id, useExtKey, cached = false) {
      const k = 'getInvoice' + id + '_' + useExtKey;
      const myCipherK = k;
      const myCipherR = k + 'R';
      const myCipher = cipher(myCipherK);
      if(cached)
      {
        var v = await storage.get(myCipher(storageName));
        if(v !== null){
          const myCipherD = decipher(myCipherR);
          return JSON.parse(myCipherD(v));
        }
      }

      try {
        let params = {};
        if (useExtKey) {
          params = {
            useExtKey: true,
          };
        }

        let invoice = null;
        const {data} = await axios.get('/services/rest/portalapi/Erp/v1/invoices/' + id, {params});
        if (typeof data.getInvoiceResponse.invoice !== "undefined") invoice = data.getInvoiceResponse.invoice;
        const _return = {success: true, data: invoice};
        const myCipher2 = cipher(myCipherR);
        storage.set(myCipher(storageName), myCipher2(JSON.stringify(_return)));
        return _return;
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async getInvoiceDetail(id, sections, cached = false) {
      const k = 'getInvoiceDetail' + id + '_' + sections;
      const myCipherK = k;
      const myCipherR = k + 'R';
      const myCipher = cipher(myCipherK);
      if(cached)
      {
        var v = await storage.get(myCipher(storageName));
        if(v !== null){
          const myCipherD = decipher(myCipherR);
          return JSON.parse(myCipherD(v));
        }
      }

      try {
        let details = null;
        const {data} = await uninterceptedAxiosInstance.get('/services/rest/portalapi/Erp/v1/invoices/' + id + '/detail', {params: {sections}});
        if (typeof data.invoiceDetailResponse !== "undefined") details = data.invoiceDetailResponse;

        const _return = {success: true, data: details, reference: sections};
        const myCipher2 = cipher(myCipherR);
        storage.set(myCipher(storageName), myCipher2(JSON.stringify(_return)));
        return _return;
      } catch (error) {

        return {
          success: false,
          data: error.message,
          errors: (error.response?.data ? error.response.data : ''),
          reference: sections
        };
      }
    },

    async getInvoiceInstallments({invoiceId, contractId}) {
      let installments = [];
      let params = {
        invoiceId,
        contractId,
      };
      try {
        const {data} = await uninterceptedAxiosInstance.get('/services/rest/portalapi/Erp/v1/installments', {params});
        if (typeof data.findInstallmentsResponse.installments.installment !== "undefined")
          installments = data.findInstallmentsResponse.installments.installment;

        if (!Array.isArray(installments)) installments = [installments];

        return {success: true, data: installments};
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async postRequestInformation(values) {
      try {
        let params = {
          "infoRequest": values
        };
        const {data} = await axios.post('/services/rest/portalapi/Erp/v1/requests/information', params);
        const response = typeof data.infoResponse.requestId !== "undefined" && data.infoResponse.requestId > 0;
        return {success: response};
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async postRequestInstallments(id, key) {
      try {
        let params = {
          "installmentsRequest": {
            "invoice": {
              id,
              key
            }
          }
        };
        const {data} = await axios.post('/services/rest/portalapi/Erp/v1/requests/installments', params);
        const response = typeof data.installmentsResponse.requestId !== "undefined" && data.installmentsResponse.requestId > 0;
        return {success: response};
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async postRequestPayments(id, totalAmount, payKind = "Invoices", gatewayId = 1) {
      try {
        let params = {
          "createNewPaymentRequest": {
            gatewayId,
            payKind,
            totalAmount,
            items: {
              item: id,
            }
          }
        };
        const {data} = await uninterceptedAxiosInstance.post('/services/rest/portalapi/Erp/v1/payments', params);
        const response = typeof data.createNewPaymentResponse.payment.id !== "undefined" && data.createNewPaymentResponse.payment.id > 0;
        let redirectUrl = null;
        if (response) {
          redirectUrl = data.createNewPaymentResponse.redirectUrl;
          if(document.location.hostname == 'localhost')
          {
            redirectUrl = redirectUrl.replace('_MY_URL_BACK_KO_', new URL('/invoice/' + id + '/payment_ko', 'https://myappenergy.com/'));
            redirectUrl = redirectUrl.replace('_MY_URL_BACK_OK_', new URL('/invoice/' + id + '/payment_ok', 'https://myappenergy.com/'));
          }
          else
          {
            redirectUrl = redirectUrl.replace('_MY_URL_BACK_KO_', new URL('/invoice/' + id + '/payment_ko', document.location));
            redirectUrl = redirectUrl.replace('_MY_URL_BACK_OK_', new URL('/invoice/' + id + '/payment_ok', document.location));
          }

        }

        return {success: response, redirectUrl};
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async postRequestInstallmentsPayments(invoiceId, ids, totalAmount, payKind = "Installments", gatewayId = 1) {
      try {
        let params = {
          "createNewPaymentRequest": {
            gatewayId,
            payKind,
            totalAmount,
            items: {
              item: ids,
            }
          }
        };
        const {data} = await axios.post('/services/rest/portalapi/Erp/v1/payments', params);
        const response = typeof data.createNewPaymentResponse.payment.id !== "undefined" && data.createNewPaymentResponse.payment.id > 0;
        let redirectUrl = null;
        if (response) {
          redirectUrl = data.createNewPaymentResponse.redirectUrl;
          redirectUrl = redirectUrl.replace('_MY_URL_BACK_KO_', new URL('/invoice/' + invoiceId + '/payment_ko', document.location));
          redirectUrl = redirectUrl.replace('_MY_URL_BACK_OK_', new URL('/invoice/' + invoiceId + '/payment_ok', document.location));
        }

        return {success: response, redirectUrl};
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async getPayment(id) {
      try {
        let params = {
          useExtKey: true,
        };
        const {data} = await uninterceptedAxiosInstance.get('/services/rest/portalapi/Erp/v1/payments/' + id, {params: params});
        const response = typeof data.getPaymentResponse.payment !== "undefined" && data.getPaymentResponse.payment.id > 0;
        return {success: response, data: data.getPaymentResponse.payment};
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async putPayments(body, userOutcome) {
      try {
        let paymentParams = _cloneDeep(body);
        paymentParams.userOutcomeDate = moment().locale('it').format('YYYY-MM-DDTHH:mm:ss');
        paymentParams.userOutcome = userOutcome;
        let params = {
          "modifyPaymentRequest": {
            payment: paymentParams,
          }
        };
        await axios.put('/services/rest/portalapi/Erp/v1/payments', params);
        return {success: true};
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async getInsolvencies(filters) {
      try {
        let insolvencies = [];
        const sessionStore = useSessionStore();
        const modules = sessionStore.module;
        if( typeof(modules) !== 'undefined' && Array.isArray(modules) && modules.some(element => element.key === 'panoram'))
        {
          let params = {
            customerId: null,
            supplyId: null,
            entityType: null,
          };
          params = {...params, ...filters};

          const {data} = await uninterceptedAxiosInstance.get('/services/rest/portalapi/Erp/v1/insolvencies', {params});
          if (typeof data.findInsolvenciesResponse.insolvencies.insolvency !== "undefined")
            insolvencies = data.findInsolvenciesResponse.insolvencies.insolvency;
        }

        if (!Array.isArray(insolvencies)) insolvencies = [insolvencies];

        return {success: true, data: insolvencies};
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },


    async getCustomers(filters, pagingTake = 100, pagingSkip = 0, cached = false) {
      const k = 'getCustomers' + JSON.stringify(filters) + '_' + pagingTake + '_' + pagingSkip;
      const myCipherK = k;
      const myCipherR = k + 'R';
      const myCipher = cipher(myCipherK);
      if(cached)
      {
        var v = await storage.get(myCipher(storageName));
        if(v !== null){
          const myCipherD = decipher(myCipherR);
          return JSON.parse(myCipherD(v));
        }
      }

      let customers = [];
      let params = {
        pagingSkip,
        pagingTake,
      };
      params = {...params, ...filters};
      try {
        const {data} = await axios.get('/services/rest/portalapi/Erp/v1/customers', {params});
        if (typeof data.findCustomersResponse.customers.customer !== "undefined")
          customers = data.findCustomersResponse.customers.customer;

        if (!Array.isArray(customers)) customers = [customers];

        const _return = {success: true, data: customers};
        const myCipher2 = cipher(myCipherR);
        storage.set(myCipher(storageName), myCipher2(JSON.stringify(_return)));
        return _return;
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async getCustomer(id, useUninterceptedAxiosInstance = false, useExtKey = false) {
      try {
        let customer = null;
        let axClient = ( useUninterceptedAxiosInstance ? uninterceptedAxiosInstance : axios);

        var params = {};
        if (useExtKey) {
          params.useExtKey = true;
        }

        const {data} = await axClient.get('/services/rest/portalapi/Erp/v1/customers/' + id, {params});
        if (typeof data.getCustomerResponse.customer !== "undefined") customer = data.getCustomerResponse.customer;

        return {success: true, data: customer};
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async setCustomer(customer) {
      this.$patch({
        customer: customer,
        customerId: customer?.id
      })
      if (customer) localStorage.setItem('_cId', customer.id);
      else localStorage.removeItem('_cId');
    },


    async getDocuments(filters, archiviationKey) {
      let documents = [];
      let params = {
        archiviationKey,
      };
      params = {...params, ...filters};
      try {
        const {data} = await uninterceptedAxiosInstance.get('/services/rest/portalapi/Erp/v1/documents', {params});
        if (typeof data.findDocumentsResponse.documents.document !== "undefined")
          documents = data.findDocumentsResponse.documents.document;

        if (!Array.isArray(documents)) documents = [documents];

        return {success: true, data: documents};
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async getDocument(id, documentType = null) {
      try {
        let document = null;
        const params = {
          documentType
        };
        const {data} = await uninterceptedAxiosInstance.get('/services/rest/portalapi/Erp/v1/documents/' + id, {params: params});
        if (typeof data.getDocumentResponse.document !== "undefined") document = data.getDocumentResponse.document;

        return {success: true, data: document};
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },


    async getRequests(filters, pagingTake = 100, pagingSkip = 0) {
      let requests = [];
      let params = {
        pagingSkip,
        pagingTake,
        customerKey: this.customer.key
      };
      params = {...params, ...filters};
      try {
        const {data} = await axios.get('/services/rest/portalapi/Erp/v1/requests', {params});
        if (typeof data.findRequestsResponse.requests.request !== "undefined")
          requests = data.findRequestsResponse.requests.request;

        if (!Array.isArray(requests)) requests = [requests];

        return {success: true, data: requests};
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async getRequest(id) {
      try {
        let request = null;
        const {data} = await axios.get('/services/rest/portalapi/Erp/v1/requests/' + id, {params: {ticketKey: id}});
        if (typeof data.getRequestResponse.request !== "undefined") request = data.getRequestResponse.request;

        return {success: true, data: request};
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async checkRegistryChangeInProgress(filters, pagingTake = 100, pagingSkip = 0) {
      let requests = [];
      let inProgress = false;
      let params = {
        pagingSkip,
        pagingTake,
        requestType: 'Var_Anagrafica',
        customerKey: this.customer.key
      };
      params = {...params, ...filters};
      try {
        const {data} = await uninterceptedAxiosInstance.get('/services/rest/portalapi/Erp/v1/requests', {params});
        if (typeof data.findRequestsResponse.requests.request !== "undefined")
          requests = data.findRequestsResponse.requests.request;

        if (!Array.isArray(requests)) requests = [requests];

        if (requests.length) {
          const sorted = requests.sort((a, b) => (a.CreatedDate < b.CreatedDate ? 1 : -1));
          const filtered = requests.filter((r) => r.CreatedDate === sorted[0].CreatedDate)
          inProgress = filtered.some((r) => r.PhaseCode === 'IR010');
        }

        return {success: true, inProgress};
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async requestEmailInvoice(contract) {
      const params = {
        "emailInvoiceRequest": {
          contract
        }
      };
      try {
        const {data} = await axios.post('/services/rest/portalapi/Erp/v1/requests/emailInvoice', params);
        const id = data.emailInvoiceResponse.requestId;
        return {success: id > 0};
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async modifyBillingOptionsRequest(options) {
      const params = {
        "modifyBillingOptionsRequest": {
          billingOptions: options
        }
      };
      try {
        const {data} = await axios.post('/services/rest/portalapi/Erp/v1/requests/billingOptions', params);
        const id = data.modifyBillingOptionsResponse.requestId;
        return {success: id > 0};
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },


    async getMaintenanceList(filters, pagingTake = 100, pagingSkip = 0, cached = false) {

      const k = 'getMaintenanceList' + JSON.stringify(filters) + '_' + pagingTake + '_' + pagingSkip;
      const myCipherK = k;
      const myCipherR = k + 'R';
      const myCipher = cipher(myCipherK);
      if(cached)
      {
        var v = await storage.get(myCipher(storageName));
        if(v !== null){
          const myCipherD = decipher(myCipherR);
          return JSON.parse(myCipherD(v));
        }
      }

      let maintenance = [];
      let params = {
        pagingSkip,
        pagingTake,
        customerKey: this.customer?.key
      };
      params = {...params, ...filters};
      try {
        const {data} = await axios.get('/services/rest/portalapi/Erp/v1/contracts/maintenance', {params});
        if (typeof data.findMaintenanceContractsResponse.maintenanceContracts.maintenanceContract !== "undefined")
          maintenance = data.findMaintenanceContractsResponse.maintenanceContracts.maintenanceContract;

        if (!Array.isArray(maintenance)) maintenance = [maintenance];

        const _return = {success: true, data: maintenance};
        const myCipher2 = cipher(myCipherR);
        storage.set(myCipher(storageName), myCipher2(JSON.stringify(_return)));
        return _return;
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async getConsumptions(filters, pagingTake = 100, pagingSkip = 0, cached = false) {

      const k = 'getConsumptions' + JSON.stringify(filters) + '_' + pagingTake + '_' + pagingSkip;
      const myCipherK = k;
      const myCipherR = k + 'R';
      const myCipher = cipher(myCipherK);
      if(cached)
      {
        var v = await storage.get(myCipher(storageName));
        if(v !== null){
          const myCipherD = decipher(myCipherR);
          return JSON.parse(myCipherD(v));
        }
      }

      let consumptions = [];
      let params = {
        pagingSkip,
        pagingTake,
      };
      params = {...params, ...filters};
      try {
        const {data} = await uninterceptedAxiosInstance.get('/services/rest/portalapi/Erp/v1/consumptions', {params});
        if (typeof data.findConsumptionsResponse !== "undefined") consumptions = data.findConsumptionsResponse;
        const _return = {success: true, data: consumptions, reference: 'getConsumptions'};
        const myCipher2 = cipher(myCipherR);
        storage.set(myCipher(storageName), myCipher2(JSON.stringify(_return)));
        return _return;
      } catch (error) {

        return {
          success: false,
          data: error.message,
          errors: (error.response?.data ? error.response.data : ''),
          reference: 'getConsumptions'
        };
      }
    },


    async getReadings(filters, orderBy = 'Desc', pagingTake = 100, pagingSkip = 0) {
      let readings = [];
      let params = {
        pagingSkip,
        pagingTake,
        orderBy,
        startDate: moment().subtract(2, 'years').format('YYYY-MM-DD')
      };
      params = {...params, ...filters};
      try {
        const {data} = await axios.get('/services/rest/portalapi/Erp/v1/readings', {params});
        if (typeof data.findReadingsResponse.readings.reading !== "undefined")
          readings = data.findReadingsResponse.readings.reading;

        if (!Array.isArray(readings)) readings = [readings];

        return {success: true, data: readings};
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },


    async getTown(stxt, signal, cnt = 10) {
      const params = {
        stxt,
        cnt,
      };
      try {
        let list = [];
        const {data} = await axios.get('/services/rest/stradario/getTown', {params, signal});
        if (typeof data.getTownResponse.TownList !== "undefined")
          list = list.concat(data.getTownResponse.TownList);
        if (typeof data.getTownResponse.VillageList !== "undefined")
          list = list.concat(data.getTownResponse.VillageList);

        return {success: true, data: list};
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async getStreet(stxt, tKey, signal, cnt = 10) {
      const params = {
        stxt,
        tKey,
        cnt,
      };
      try {
        let list = [];
        const {data} = await axios.get('/services/rest/stradario/getStreet', {params, signal});
        if (typeof data.getStreetResponse.list !== "undefined")
          list = data.getStreetResponse.list;
        if (!Array.isArray(list)) list = [list];

        return {success: true, data: list};
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async addressCheck(payload) {
      const params = {
        State: "",
        CountryCode: "IT",
        Country: "ITALIA",
        Company: "SGR",
      };
      params.HouseNumber = payload.civicNumber;
      params.StreetGeneric = payload.streetGeneric;
      params.StateCode = payload.stateCode;
      params.Locality = payload.locality;
      params.Street = payload.streetName;
      params.City = payload.city;
      params.PostalCode = payload.postalCode;
      try {
        const {data} = await axios.post('/services/rest/stradario/checkAddress', params);
        const score = Number(data.root.Indirizzo.Score);
        return {success: score >= 50};
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async changeShippingAddress(payload, contract) {
      const customer = this.getCurrentCustomer;
      const params = {
        "shippingAddressChangeRequest": {
          "customer": {
            id: customer.id,
            key: customer.key,
            contract: {
              id: contract.id,
              key: contract.key,
              shippingAddress: {
                streetGeneric: payload.streetGeneric,
                streetName: payload.streetName,
                civicNumber: payload.civicNumber,
                city: payload.city,
                province: payload.province,
                postalCode: payload.postalCode,
                locality: payload.locality,
                istatCode: payload.istatCode,
                country: "ITALIA",
                civicNumberExt: payload.civicNumberExt,
                floor: payload.floor,
                building: payload.building,
                flatNumber: payload.flatNumber,
              }
            }
          }
        }
      };
      try {
        const {data} = await axios.post('/services/rest/portalapi/Erp/v1/requests/shippingAddress', params);
        const id = data.shippingAddressChangeResponse.requestId;
        return {success: id > 0};
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async getReadingWindow(supplyKey) {
      const params = {
        supplyKey
      };
      try {
        let window = null;
        const {data} = await uninterceptedAxiosInstance.get('/services/rest/portalapi/Erp/v1/metering/readingWindow', {params});
        if (typeof data.getReadingWindowResponse !== "undefined")
          window = _cloneDeep(data.getReadingWindowResponse);

        return {success: true, data: window};
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async getDescribeMeterGroup(supplyKey, useExtKey = false) {
      var params = {
        supplyKey
      };

      if(useExtKey)
      {
        params.useExtKey = true;
      }

      try {
        let res = null;
        const {data} = await uninterceptedAxiosInstance.get('/services/rest/portalapi/Erp/v1/metering/describeMeterGroup', {params});
        if (typeof data.describeMeterGroupResponse !== "undefined")
          res = _cloneDeep(data.describeMeterGroupResponse);
        return {success: true, data: res};
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async getDescribeMeterGroupMisure(supplyKey) {
      const params = {
        supplyKey
      };
      try {
        let misure = null;
        const {data} = await uninterceptedAxiosInstance.get('/services/rest/portalapi/Erp/v1/metering/describeMeterGroup', {params});
        if (typeof data.describeMeterGroupResponse !== "undefined")
          misure = _cloneDeep(data.describeMeterGroupResponse.Misure.Misura);
        if (!Array.isArray(misure)) misure = [misure];
        const res = misure.filter((m) => m.ChannelTypeCode === 1);

        return {success: true, data: res};
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async insertReadings(params) {
      try {
        const {data} = await axios.post('/services/rest/portalapi/Erp/v1/metering/insertReadings', params);
        return {
          success: data.insertReadingsResponse.results.result.success,
          data: data.insertReadingsResponse.results.result
        };
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async supplyOfferHasHours(offerKey, supplyKey, cached = false) {
      const k = 'supplyOfferHasHours2' + offerKey + supplyKey;
      if(cached)
      {
        var v = await storage.get(k);
        if(v !== null){
          return JSON.parse(v);
        }
      }
      try {
        const self = this;
        let hasHours = false;
        return await self.getOffer(encodeURIComponent(offerKey)).then((res) => {
          if (res.success) {
            if (res.data.priceType === 'Orario') {
              return self.getDescribeMeterGroup(supplyKey).then((res1) => {
                if (res1.success) {
                  const now = moment();
                  hasHours = Boolean(res1.data.fullCapacityStartDate && moment(res1.data.fullCapacityStartDate).isBefore(now) );
                  const _return = {success: true, data: hasHours};
                  storage.set(k, (JSON.stringify(_return)));
                  return _return;
                }else{
                  const _return = {success: false, data: false};
                  storage.set(k, (JSON.stringify(_return)));
                  return _return;
                }
              })
            }else{
              const _return = {success: false, data: false};
              storage.set(k, (JSON.stringify(_return)));
              return _return;
            }
          }else{
            const _return = {success: false, data: false};
            storage.set(k, (JSON.stringify(_return)));
            return _return;
          }
        })
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async getQuotations(filters) {
      try {
        let params = {
          quotationType: 'PUN',
          detailLevel: 'Hour',
          startDate: moment().format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD'),
        };
        params = {...params, ...filters};
        let quotations = [];
        const {data} = await axios.get('/services/rest/portalapi/Erp/v1/quotations', {params});
        if (typeof data.findQuotationsResponse.quotations.quotation !== "undefined")
          quotations = data.findQuotationsResponse.quotations.quotation;

        if (!Array.isArray(quotations)) quotations = [quotations];

        return {success: true, data: quotations};
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async checkProposal(id) {
      try {

        const {data} = await axios.get('/services/rest/portalapi/Erp/v1/proposals/' + id + '/check-rules');

        var show = false;
        if (typeof data.proposalCheckRulesResponse.proposalId !== "undefined")
          show = data.proposalCheckRulesResponse.valid;

        return {success: true, data: show};
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async getProposals(activeOnly = true, accountKey = null, pagingTake = 1000, pagingSkip = 0) {
      try {
        let params = {
          activeOnly: activeOnly,
          pagingSkip: pagingSkip,
          pagingTake: pagingTake,
        };
        if(accountKey)
          params.accountKey = accountKey;

        let proposals = [];
        const {data} = await axios.get('/services/rest/portalapi/Erp/v1/proposals', {params});
        if (typeof data.findProposalsResponse.proposals.proposal !== "undefined")
          proposals = data.findProposalsResponse.proposals.proposal;

        if (!Array.isArray(proposals)) proposals = [proposals];

        if(proposals.length > 0)
        {
          proposals = proposals.filter((proposalTmp) => {
            var nowDate = new Date();
            var startDate = new Date(proposalTmp.startDate);
            var endDate = new Date(proposalTmp.endDate);
            var dateFix = new Date(2024, 0, 16);
            dateFix.setHours(0, 0, 0);
            if(nowDate >= dateFix)
            {
              startDate.setHours(0, 0, 0);
              endDate.setHours(23, 59, 59);
            }

            if ((startDate <= nowDate || !proposalTmp.startDate) && (!proposalTmp.endDate || endDate >= nowDate) ) {
              return true;
            }
            return false;
          });
        }

        return {success: true, data: proposals};
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async getProposal(id) {
      try {
        let proposal = null;
        const {data} = await axios.get('/services/rest/portalapi/Erp/v1/proposals/' + id);
        if (typeof data.getProposalResponse.proposal !== "undefined")
          proposal = data.getProposalResponse.proposal;

        if(proposal)
        {

          var nowDate = new Date();
          var startDate = new Date(proposal.startDate);
          var endDate = new Date(proposal.endDate);

          var dateFix = new Date(2024, 0, 16);
          dateFix.setHours(0, 0, 0);
          if(nowDate >= dateFix)
          {
            startDate.setHours(0, 0, 0);
            endDate.setHours(23, 59, 59);
          }

          if ((startDate <= nowDate || !proposal.startDate) && (!proposal.endDate || endDate >= nowDate) ) {
            return {success: true, data: proposal};
          }
          else
          {
            return {success: false, data: 'Proposta non trovata'};
          }
        }
      } catch (error) {

        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },
  },
})