<template>
  <!-- start footer -->
  <footer class="py-20 mt-10" v-if="!$isApp">
    <div class="footer-widget-area">
      <ion-grid fixed>
        <template v-if="contractsStore.showGraph">
          <ion-row>
            <ion-col size="12" class="fs-10">
              <ol>
                <li class="mb-1">
                  {{ $t('legend.first_point')}}<br>
                </li>
                <li v-if="contractsStore.showLegend">
                  {{ $t('legend.F1')}}<br>
                  {{ $t('legend.F2')}}<br>
                  {{ $t('legend.F3')}}<br>
                  {{ $t('legend.text')}}
                </li>
              </ol>
            </ion-col>
          </ion-row>
          <hr class="my-4">
        </template>

        <ion-row v-if="company == 'sgr'">
          <ion-col size="12" sizeMd="6" sizeLg="4">
            <h6>SGR Point</h6>
            <ul class="menu">
              <li class="menu-item">
                <a href="https://www.sgrlucegas.com/sgr-point/" target="_blank" title="Sede principale">
                  Sede principale
                </a>
              </li>
              <li class="menu-item">
                <a href="https://www.sgrlucegas.com/sgr-point/#sedi-secondarie" target="_blank"
                   title="Sedi secondarie">
                  Sedi secondarie
                </a>
              </li>
              <li class="menu-item">
                <a href="https://www.sgrlucegas.com/sgr-point/#energy-point" target="_blank" title="Energypoint">
                  Energypoint
                </a>
              </li>
            </ul>
          </ion-col>
          <ion-col size="12" sizeMd="6" sizeLg="4">
            <h6>Azienda</h6>
            <ul class="menu">
              <li class="menu-item">
                <a href="https://www.sgrlucegas.com/azienda/" target="_blank" title="Mission">
                  Mission
                </a>
              </li>
              <li class="menu-item">
                <a href="https://www.sgrlucegas.com/filosofia/" target="_blank" title="Filosofia">
                  Filosofia
                </a>
              </li>
              <li class="menu-item">
                <a href="https://www.sgrlucegas.com/azienda/#storia" target="_blank" title="Storia">
                  Storia
                </a>
              </li>
              <li class="menu-item">
                <a href="https://www.sgrlucegas.com/codice-etico/" target="_blank" title="Codice Etico">
                  Codice Etico
                </a>
              </li>
              <li class="menu-item">
                <a href="https://www.sgrlucegas.com/certificazioni/" target="_blank" title="Certificazioni">
                  Certificazioni
                </a>
              </li>
              <li class="menu-item">
                <a href="https://www.grupposgr.it/wp-content/uploads/2020/07/BilancioSostenibilita2019_ridotto.pdf"
                   target="_blank" title="Bilancio di Sostenibilità">
                  Bilancio di Sostenibilità
                </a>
              </li>
              <li class="menu-item">
                <a href="https://www.sgrlucegas.com/rating-di-legalita/" target="_blank" title="Rating di Legalità">
                  Rating di Legalità
                </a>
              </li>
              <li class="menu-item">
                <a href="https://www.sgrlucegas.com/lavora-con-noi/" target="_blank" title="Lavora con noi">
                  Lavora con noi
                </a>
              </li>
            </ul>
          </ion-col>
          <ion-col size="12" sizeMd="6" sizeLg="4">
            <h6>Diritti del consumatore</h6>
            <ul class="menu">
              <li class="menu-item">
                <a href="https://www.sgrlucegas.com/segnalazioni-e-risoluzione-eventuali-controversie/"
                   target="_blank" title="Segnalazioni e Risoluzione Eventuali Controversie">
                  Segnalazioni e Risoluzione Eventuali Controversie
                </a>
              </li>
              <li class="menu-item">
                <a href="http://www.sportelloperilconsumatore.it/" target="_blank" title="Sportello per il consumatore">
                  Sportello per il consumatore
                </a>
              </li>
              <li class="menu-item">
                <a href="https://www.arera.it/it///190701.htm" target="_blank"
                   title="Evoluzione dei mercati al dettaglio">
                  Evoluzione dei mercati al dettaglio
                </a>
              </li>
              <li class="menu-item">
                <a href="https://www.sgrlucegas.com/canone-rai-in-bolletta-2/" target="_blank"
                   title="Canone RAI in bolletta">
                  Canone RAI in bolletta
                </a>
              </li>
              <li class="menu-item">
                <a href="https://www.sgrlucegas.com/app/uploads/2020/11/INDAGINE-SULLA-QUALIT%C3%80-DEI-CALL-CENTER-ANNO-2020.pdf"
                   target="_blank" title="Indagine sulla qualità dei call center – Anno 2020">
                  Indagine sulla qualità dei call center – Anno 2020
                </a>
              </li>
              <li class="menu-item">
                <a href="https://www.sgrlucegas.com/offerta/servizio-tutela-gas/" target="_blank"
                   title="Servizio Tutela Gas">
                  Servizio Tutela Gas
                </a>
              </li>
            </ul>
          </ion-col>

          <ion-col size="12" sizeMd="6" sizeLg="4">
            <h6>Agevolazioni</h6>
            <ul class="menu">
              <li class="menu-item">
                <a href="https://www.sgrlucegas.com/bonus-sociale/" target="_blank" title="Bonus sociale">
                  Bonus sociale
                </a>
              </li>
            </ul>
          </ion-col>
          <ion-col size="12" sizeMd="6" sizeLg="4">
            <h6>Download modulistica</h6>
            <ul class="menu">
              <li class="menu-item">
                <a href="https://www.sgrlucegas.com/modulistica/gas-domestico/" target="_blank" title="Gas Domestico">
                  Gas Domestico
                </a>
              </li>
              <li class="menu-item">
                <a href="https://www.sgrlucegas.com/modulistica/luce-domestico/" target="_blank" title="Luce Domestico">
                  Luce Domestico
                </a>
              </li>
              <li class="menu-item">
                <a href="https://www.sgrlucegas.com/modulistica/gas-imprese/" target="_blank" title="Gas Imprese">
                  Gas Imprese
                </a>
              </li>
              <li class="menu-item">
                <a href="https://www.sgrlucegas.com/modulistica/luce-imprese/" target="_blank" title="Luce Imprese">
                  Luce Imprese
                </a>
              </li>
              <li class="menu-item">
                <a href="https://www.sgrlucegas.com/app/uploads/modulistica/SGR-A-GL-600.pdf" target="_blank"
                   title="Modulo Reclami">
                  Modulo Reclami
                </a>
              </li>
            </ul>
          </ion-col>
          <ion-col size="12" sizeMd="6" sizeLg="4">
            <h6>Info</h6>
            <ul class="menu">
              <li class="menu-item">
                <a href="https://www.sgrlucegas.com/composizione-mix-energetico/" target="_blank"
                   title="Composizione mix energetico">
                  Composizione mix energetico
                </a>
              </li>
              <li class="menu-item">
                <a href="https://www.sgrlucegas.com/glossario-bollette/" target="_blank" title="Glossario Bollette">
                  Glossario Bollette
                </a>
              </li>
              <li class="menu-item">
                <a href="https://www.sgrlucegas.com/guida-alla-bolletta/" target="_blank" title="Guida alla Bolletta">
                  Guida alla Bolletta
                </a>
              </li>
              <li class="menu-item">
                <a href="https://www.sgrlucegas.com/gestione-calore/" target="_blank" title="Gestione calore">
                  Gestione calore
                </a>
              </li>
              <li class="menu-item">
                <a href="https://www.sgrlucegas.com/teleriscaldamento/" target="_blank" title="Teleriscaldamento">
                  Teleriscaldamento
                </a>
              </li>
            </ul>
          </ion-col>
        </ion-row>
        <ion-row v-if="company == 'ast'">
          <ion-col size="12" sizeMd="6" sizeLg="4">
            <div class="logo_w">
              <img :src="require('@/assets/media/logo_astea.svg')" class="d-inline " alt="Astea Energia"/>
            </div>
            <p class="fs-9 lh-2 text-white">Copyright © ASTEA Energia SPA<br>
              Via Guazzatore 163, 60027 Osimo (AN)<br>
              P.I. 02078510423<br>
              Iscr. Reg. Imprese di AN n. 02078510423<br>
              cap. soc. € 120.000,00 i.v.<br>
              Direzione e coordinamento di Gasrimini Holding spa<br>
              <a href="https://www.asteaenergia.it/documenti/" target="_blank">Documenti</a> – <a href="https://www.asteaenergia.it/privacy-cookie-policy/" target="_blank">Privacy & Cookie Policy</a> – <a href="https://www.asteaenergia.it/rating-di-legalita/" target="_blank">Rating di Legalità</a></p>
          </ion-col>
          <ion-col size="12" sizeMd="6" sizeLg="4">
            <h6>Contatti</h6>
            <ul class="menu">
              <li class="menu-item">
                Via Guazzatore 163, 60027 Osimo (AN)
              </li>
              <li class="menu-item">
                Telefono:
                <a href="tel:800992627">
                  800 99 26 27
                </a>
              </li>
              <li class="menu-item">
                E-mail:
                <a href="mailto:clienti@asteaenergia.it" target="_blank">
                  clienti@asteaenergia.it
                </a>
              </li>
              <li class="menu-item">
                Sito web:
                <a href="https://www.asteaenergia.it/" target="_blank">
                  www.asteaenergia.it
                </a>
              </li>
              <li class="menu-item">
                Pagina:
                <a href="https://www.facebook.com/asteaenergia.it/" target="_blank">
                  Facebook
                </a>
              </li>
            </ul>
          </ion-col>
          <ion-col size="12" sizeMd="6" sizeLg="4">
            <h6>Links utili</h6>
            <ul class="menu">
              <li class="menu-item"><a href="https://www.asteaenergia.it/modulistica-online/">Modulistica Online</a></li>
              <li class="menu-item"><a href="https://www.asteaenergia.it/modulistica/AST-A-GL-600.pdf">Modulo Reclami</a></li>
              <li class="menu-item"><a href="https://www.asteaenergia.it/faq-myastea-energia/">Domande Frequenti</a></li>
              <li class="menu-item"><a href="https://www.arera.it/it/190701.htm">Evoluzione dei mercati al dettaglio</a></li>
              <li class="menu-item"><a href="https://www.sportelloperilconsumatore.it/">Sportello per il consumatore Energia e Ambiente</a></li>
              <li class="menu-item"><a href="https://www.asteaenergia.it/contenimento-consumi-gas/">Contenimento consumi gas</a></li>
              <li class="menu-item"><a href="https://www.asteaenergia.it/composizione-mix-energetico/">Composizione mix energetico</a></li>
              <li class="menu-item"><a href="https://www.asteaenergia.it/codice-etico/">Codice Etico</a></li>
              <li class="menu-item"><a href="https://www.asteaenergia.it/bonus-sociale/">Bonus sociale</a></li>
              <li class="menu-item"><a href="https://www.asteaenergia.it/glossario-bollette/">Glossario Bollette</a></li>
              <li class="menu-item"><a href="https://www.asteaenergia.it/bollettaclick/">Bollett@click</a></li>
              <li class="menu-item"><a href="https://www.asteaenergia.it/segnalazioni-e-risoluzione-controversie/">Segnalazioni e Risoluzione Controversie</a></li>
              <li class="menu-item"><a href="https://www.asteaenergia.it/lavora-con-noi/">Lavora con noi</a></li>
            </ul>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>

    <!-- start copyright -->
    <div class="footer-bottom mt-6" v-if="company == 'sgr'">
      <ion-grid fixed>
        <div class="top-link">
          <ul class="menu">
            <li class="menu-item">
              <a href="https://www.iubenda.com/privacy-policy/24776967" target="_blank" title="Privacy Policy">
                Privacy Policy
              </a>
            </li>
            <li class="menu-item">
              <a href="https://www.iubenda.com/privacy-policy/24776967/cookie-policy" target="_blank" title="Cookie Policy">
                Cookie Policy
              </a>
            </li>
            <li class="menu-item">
              <a href="https://www.iubenda.com/privacy-policy/24776967/cookie-policy"  title="Preferenze Cookie" data-iub-enabled="1">
                Preferenze Cookie
              </a>
            </li>
          </ul>
        </div>
        <hr class="my-4">
        <ion-row>
          <ion-col size="8" class="fs-10">
            <div class="credits fs-9">
              © Copyright I SGR Servizi S.p.A. CF / P.Iva/N.iscr.Reg.Impr. della Romagna – Forlì – Cesena e Rimini
              00338000409 REA RN 0283234 Cap. Soc.: € 5.982.262,00 int. versato Dir. e Coordin. Gasrimini Holding S.p.A.
            </div>
          </ion-col>
          <ion-col size="4" class="fs-10">
            <div class="fs-9" style="text-align: right">
              v.{{ version }}
            </div>
          </ion-col>
        </ion-row>

      </ion-grid>
      <!-- end copyright -->
    </div>
  </footer>
  <!-- end footer -->
</template>

<script>
import {computed, defineComponent} from 'vue';
import {
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/vue';
import {useContractsStore} from '@/stores/contracts'
import {useSessionStore} from '@/stores/session'
import p from '/package.json';

export default defineComponent({
  name: 'FooterComponent',
  components: {
    IonGrid,
    IonRow,
    IonCol,
  },
  setup() {
    const sessionStore = useSessionStore();
    const contractsStore = useContractsStore();
    const version = p.version;
    const company = computed(() => sessionStore.getCompany);
    return {contractsStore, version, company};
  },
})
</script>

<style lang="scss" scoped>
footer {
  background: #ecf1f4 0% 0% no-repeat padding-box;
  opacity: 1;

  .footer-widget-area {
    h6 {
      color: var(--ion-text-color);
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
    }

    ul {
      padding-left: 0;
      margin-bottom: 0;

      .menu-item {
        display: block;
        padding-bottom: 8px;

        a {
          color: var(--ion-color-medium);
          text-decoration: none;
          font-size: 12px;
          font-weight: 500;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    h7 {
      display: block;
      padding-bottom: 4px;

      a {
        font-size: 12px;
      }
    }

    .row {
      & > div {
        padding-bottom: 32px;
      }
    }
  }

  .footer-bottom {

    .top-link {
      display: flex;
      flex-wrap: wrap;


      ul {
        padding-left: 0;
        margin-bottom: 0;

        .menu-item {
          line-height: 1;
          display: inline-block;

          a {
            color: var(--ion-color-medium);
            text-decoration: none;
            font-size: 12px;
            vertical-align: middle;
            font-weight: 500;

            &:hover {
              text-decoration: underline;
            }
          }

          & + .menu-item {
            &:before {
              content: '';
              border-left: 1px solid var(--ion-color-dark);
              height: 12px;
              width: 1px;
              display: inline-block;
              vertical-align: middle;
              margin-left: 4px;
              padding-left: 4px;
            }
          }
        }
      }

      h7 {
        line-height: 1;

        a {
          color: var(--ion-color-medium);
          text-decoration: none;
          font-size: 12px;
          vertical-align: middle;
          font-weight: 500;

          &:hover {
            text-decoration: underline;
          }
        }

        & + h7 {
          &:before {
            content: '';
            border-left: 1px solid var(--ion-color-dark);
            height: 12px;
            width: 1px;
            display: inline-block;
            vertical-align: middle;
            margin-left: 4px;
            padding-left: 4px;
          }
        }
      }
    }

    .credits {
      line-height: 1;
    }
  }

  hr {
    border-bottom-width: 1px;
    border-color: var(--ion-color-medium);
  }
}

.ast footer {
  background: var(--ion-color-primary);
  color: white;
  a {
    color: white;
  }
  .logo_w {
    width: 100%;
    margin: 0px 50px 0px 0px;
    padding-bottom: 20px;
    img {
      height: 90px;
    }
  }
  .footer-widget-area ul .menu-item a {
    color: white;
    font-size: 14px;
    text-decoration: underline;
  }
  h7 {
    a {
      color: white;
    }
  }
  li a {
    color: var(--ion-color-secondary);
  }
  h6 {
    color: white;
  }
}
.lh-2 {
  line-height: 2;
}
</style>
