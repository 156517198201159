import axios from 'axios';
import {useSessionStore} from '@/stores/session'

const uninterceptedAxiosInstance = axios.create();
uninterceptedAxiosInstance.defaults.baseURL = process.env.VUE_APP_MW_HOST;
uninterceptedAxiosInstance.defaults.timeout = 15000;
uninterceptedAxiosInstance.interceptors.request.use((config) => {
  const sessionStore = useSessionStore();
  if (sessionStore.getSessionId) {
    config.headers.sessionId = sessionStore.getSessionId;
  }
  return config;
});

uninterceptedAxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

export default uninterceptedAxiosInstance;
