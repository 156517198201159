<template>
  <div class="feedback bg-primary-gradient py-8 px-9 ion-text-center" :class="{full:isFull}">
    <ion-grid fixed v-if="isFull">
      <ion-row>
        <ion-col/>
        <ion-col>
          <svg xmlns="http://www.w3.org/2000/svg" width="335.127" height="569.844" viewBox="0 0 335.127 569.844"
               class="logo">
            <path d="M-1050.547,56.656c129.842-153.7,0-233.775,0-233.775s-7.658,53.143-97.953,158.914-64.49,
                    190.549.768,268.321C-1147.732,250.115-1180.393,210.353-1050.547,56.656Zm119.66-91.165s32.668,
                    39.764-97.184,193.461,0,233.773,0,233.773,7.654-53.14,97.955-158.911S-865.625,43.265-930.887-34.51Z"
                  transform="translate(1206.871 177.119)"/>
          </svg>
        </ion-col>
        <ion-col/>
      </ion-row>
    </ion-grid>

    <div class="py-20">
      <h1 class="fs-4 fw-bold text-white my-12">{{ title }}</h1>
      <div class="text-white" v-html="description"></div>

      <template v-if="firstButton">
        <ion-button v-if="firstButton.close" color="light" fill="outline" class="mt-13"
                    @click.stop="close">
          {{ firstButton.label }}
        </ion-button>
        <ion-button v-else color="light" fill="outline" class="mt-13"
                    :href="firstButton.link">
          {{ firstButton.label }}
        </ion-button>
      </template>

      <template v-if="secondButton">
        <ion-button v-if="secondButton.close" fill="clear" size="small"
                    class="button-link text-white mx-auto mt-5"
                    @click.stop="close">
          {{ secondButton.label }}
        </ion-button>
        <ion-button v-else color="light" fill="outline" class="mt-13"
                    :href="secondButton.link">
          {{ secondButton.label }}
        </ion-button>
      </template>
    </div>
  </div>
</template>

<script>
import {
  IonButton,
  IonGrid,
  IonRow,
  IonCol
} from "@ionic/vue";
import {useSessionStore} from '@/stores/session'

export default {
  name: "FeedbackComponent",
  components: {
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    firstButton: {
      type: Object, // {label, link, close}
    },
    secondButton: {
      type: Object, // {label, link, close}
    },
    isFull: {
      type: Boolean,
      default: false,
    }
  },
  setup() {
    const sessionStore = useSessionStore();
    return {sessionStore};
  },
  methods: {
    close() {
      return this.sessionStore.clearFeedbackData();
    }
  }
}
</script>

<style lang="scss" scoped>
ion-button {
  --border-width: 1px;

  &::part(native) {
    white-space: pre-wrap;
  }
}

.logo {
  height: 48px;
  width: 100%;
  fill: var(--ion-color-white);
}

.feedback {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;

  &:not(.full) {
    height: calc(100vh - 58px);
    top: 58px;
  }
}

@media (min-width: 1025px) {
  .feedback:not(.full) {
    height: calc(100vh - 74px);
    top: 74px;
  }
}
</style>
