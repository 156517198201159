import {createRouter, createWebHistory} from '@ionic/vue-router';

const routes = [
  {
    path: '/',
    name: 'index',
    meta: { style: 'dark', requiresAuth: null },
    component: () => import('../views/IndexPage.vue'),
  },
  {
    path: '/errore',
    name: 'error',
    meta: { style: 'dark', requiresAuth: null, fullScreenApp: true },
    component: () => import('../views/Errors/ErrorPage.vue'),
  },
  {
    path: '/errore/in-attesa-di-conferma',
    name: 'error_no_customers',
    meta: { style: 'dark', requiresAuth: null, fullScreenApp: true },
    component: () => import('../views/Errors/NoCustomers.vue'),
  },
  {
    path: '/errore/server-in-manutenzione',
    name: 'error-503',
    meta: { style: 'dark', requiresAuth: null, fullScreenApp: true, noCompanyRequired: true },
    component: () => import('../views/Errors/503Page.vue'),
  },
  {
    path: '/errore/offline',
    name: 'offline',
    meta: { style: 'dark', requiresAuth: null, fullScreenApp: true, noCompanyRequired: true },
    component: () => import('../views/Errors/OfflinePage.vue'),
  },
  {
    path: '/company',
    name: 'company',
    meta: { style: 'dark', requiresAuth: null, fullScreenApp: true },
    component: () => import('../views/App/CompanySwitchPage.vue'),
  },
  {
    path: '/login',
    name: 'login',
    meta: { style: 'dark', requiresAuth: false, fullScreenApp: true },
    component: () => import('../views/Auth/LoginPage.vue'),
  },
  {
    path: '/applogin',
    name: 'app-login',
    meta: { style: 'dark', requiresAuth: false, fullScreenApp: true },
    component: () => import('../views/App/LoginPage.vue'),
  },
  {
    path: '/active-biometric',
    name: 'active_biometric',
    meta: { style: 'dark', requiresAuth: true, fullScreenApp: true },
    component: () => import('../views/App/ActiveBiometricIdPage.vue'),
  },
  {
    path: '/registrazione',
    name: 'register',
    meta: { style: 'dark', requiresAuth: false },
    component: () => import('../views/Auth/RegisterPage.vue'),
  },
  {
    path: '/registrazione/cliente-sgr',
    name: 'register_sgr_client_type',
    meta: { style: 'dark', requiresAuth: false },
    component: () => import('../views/Auth/RegisterSGRClient/StepTypePage.vue'),
  },
  {
    path: '/registrazione/cliente-sgr/:type/dati',
    name: 'register_sgr_client_2',
    meta: { style: 'dark', requiresAuth: false },
    props: true,
    component: () => import('../views/Auth/RegisterSGRClient/Step2Page.vue'),
  },
  {
    path: '/registrazione/cliente-sgr/forniture',
    name: 'register_sgr_client_supplies',
    meta: { style: 'dark', requiresAuth: false },
    component: () => import('../views/Auth/RegisterSGRClient/StepSuppliesPage.vue'),
  },
  {
    path: '/registrazione/cliente-sgr/username',
    name: 'register_sgr_client_username',
    meta: { style: 'dark', requiresAuth: false },
    component: () => import('../views/Auth/RegisterSGRClient/StepUsernamePage.vue'),
  },
  {
    path: '/registrazione/cliente-sgr/privacy',
    name: 'register_sgr_client_privacy',
    meta: { style: 'dark', requiresAuth: false },
    component: () => import('../views/Auth/RegisterSGRClient/StepPrivacyPage.vue'),
  },
  {
    path: '/registrazione/cliente-sgr/:type',
    name: 'register_sgr_client_1',
    meta: { style: 'dark', requiresAuth: false },
    props: true,
    component: () => import('../views/Auth/RegisterSGRClient/Step1Page.vue'),
  },
  {
    path: '/registrazione/cliente-sgr/:type/tax-code/:taxCode',
    name: 'register_sgr_client_tax_code',
    meta: { style: 'dark', requiresAuth: false },
    props: true,
    component: () => import('../views/Auth/RegisterSGRClient/StepTaxCodePage.vue'),
  },

  {
    path: '/registrazione/delegate',
    name: 'register_delegate_name',
    meta: { style: 'dark', requiresAuth: false },
    component: () => import('../views/Auth/RegisterDelegate/StepNamePage.vue'),
  },
  {
    path: '/registrazione/delegate/contacts',
    name: 'register_delegate_contacts',
    meta: { style: 'dark', requiresAuth: false },
    component: () => import('../views/Auth/RegisterDelegate/StepContactsPage.vue'),
  },
  {
    path: '/registrazione/delegate/address',
    name: 'register_delegate_address',
    meta: { style: 'dark', requiresAuth: false },
    component: () => import('../views/Auth/RegisterDelegate/StepAddressPage.vue'),
  },
  {
    path: '/registrazione/delegate/username',
    name: 'register_delegate_username',
    meta: { style: 'dark', requiresAuth: false },
    component: () => import('../views/Auth/RegisterDelegate/StepUsernamePage.vue'),
  },
  {
    path: '/registrazione/delegate/privacy',
    name: 'register_delegate_privacy',
    meta: { style: 'dark', requiresAuth: false },
    component: () => import('../views/Auth/RegisterDelegate/StepPrivacyPage.vue'),
  },

  {
    path: '/password-dimenticata',
    name: 'forgotten_password',
    meta: { style: 'dark', requiresAuth: false },
    component: () => import('../views/Auth/ForgottenPassword/ForgottenPage.vue'),
  },
  {
    path: '/password-dimenticata/username',
    name: 'forgotten_password_username',
    meta: { style: 'dark', requiresAuth: false },
    component: () => import('../views/Auth/ForgottenPassword/ByUsernamePage.vue'),
  },
  {
    path: '/password-dimenticata/codice-cliente',
    name: 'forgotten_password_client_code',
    meta: { style: 'dark', requiresAuth: false },
    component: () => import('../views/Auth/ForgottenPassword/ByClientCodePage.vue'),
  },
  {
    path: '/recupero-psw',
    name: 'reset_password',
    meta: { style: 'dark', requiresAuth: null },
    component: () => import('../views/Auth/ResetPasswordPage.vue'),
  },
  {
    path: '/crea-psw',
    name: 'create_password',
    meta: { style: 'dark', requiresAuth: null },
    component: () => import('../views/Auth/RegisterSGRClient/CreatePasswordPage.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: { style: 'dark', nobkapp: true, requiresAuth: true },
    component: () => import('../views/DashboardPage.vue'),
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    meta: { style: 'dark', requiresAuth: null },
    component: () => import('../views/OnboardingPage.vue'),
  },
  {
    path: '/customers',
    name: 'customers',
    meta: { style: 'dark', requiresAuth: true },
    component: () => import('../views/CustomersPage.vue'),
  },
  {
    path: '/supplies',
    name: 'supplies',
    meta: { style: 'dark', nobkapp: true, requiresAuth: true },
    component: () => import('../views/Supply/SuppliesPage.vue'),
  },
  {
    path: '/supplies/:id',
    name: 'supply',
    props: true,
    meta: { style: 'dark', requiresAuth: true },
    component: () => import('../views/Supply/SupplyPage.vue'),
  },
  {
    path: '/supplies/:id/edit-name',
    name: 'supply_edit_name',
    meta: { style: 'dark', requiresAuth: true },
    component: () => import('../views/Supply/EditNamePage.vue'),
  },
  {
    path: '/supplies/:id/edit-shipping-address',
    name: 'supply_edit_shipping_address',
    meta: { style: 'dark', requiresAuth: true },
    component: () => import('../views/Supply/EditShippingAddress.vue'),
  },
  {
    path: '/invoices',
    name: 'invoices',
    meta: { style: 'dark', nobkapp: true, requiresAuth: true },
    component: () => import('../views/Invoice/InvoicesPage.vue'),
  },
  {
    path: '/invoices-list',
    name: 'invoices_list',
    meta: { style: 'dark', requiresAuth: true },
    component: () => import('../views/Invoice/InvoicesListPage.vue'),
  },
  {
    path: '/offers/:key',
    name: 'offer',
    props: true,
    meta: { style: 'dark', requiresAuth: true },
    component: () => import('../views/Supply/OfferPage.vue'),
  },
  {
    path: '/messages',
    name: 'messages',
    meta: { style: 'light', nobkapp: true, requiresAuth: true },
    component: () => import('../views/MessagesPage.vue'),
  },
  {
    path: '/messages/:id',
    name: 'message',
    meta: { style: 'light', requiresAuth: true },
    component: () => import('../views/MessagePage.vue'),
  },
  {
    path: '/proposals/:id',
    name: 'proposal',
    meta: { style: 'light', requiresAuth: true },
    component: () => import('../views/ProposalPage.vue'),
  },

  {
    path: '/services/bollettaclick',
    name: 'service_bollettaclick',
    meta: { style: 'dark', requiresAuth: true },
    component: () => import('../views/Service/BollettaclickPage.vue'),
  },
  {
    path: '/services/activation/:index',
    name: 'service_activation',
    meta: { style: 'dark', requiresAuth: true },
    component: () => import('../views/Service/ServiceActivationPage.vue'),
  },
  {
    path: '/services/:index/:supplyid',
    name: 'service_supply',
    meta: { style: 'light', requiresAuth: true },
    component: () => import('../views/Service/ServicePage.vue'),
  },
  {
    path: '/services/:index',
    name: 'service',
    meta: { style: 'light', requiresAuth: true },
    component: () => import('../views/Service/ServicePage.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    meta: { style: 'dark', nobkapp: true, requiresAuth: true },
    component: () => import('../views/Profile/ProfilePage.vue'),
  },
  {
    path: '/profile/manage-privacy',
    name: 'manage_privacy',
    meta: { style: 'dark', requiresAuth: true },
    component: () => import('../views/Profile/ManagePrivacyPage.vue'),
  },
  {
    path: '/profile/manage-push',
    name: 'manage_push',
    meta: { style: 'dark', requiresAuth: true },
    component: () => import('../views/Profile/ManageNotificationPushPage.vue'),
  },
  {
    path: '/profile/request-delegation',
    name: 'request_delegation',
    meta: { style: 'light', requiresAuth: true },
    component: () => import('../views/Profile/RequestDelegationPage.vue'),
  },
  {
    path: '/profile/edit-email',
    name: 'edit_email',
    meta: { style: 'dark', requiresAuth: true },
    component: () => import('../views/Profile/EditEmail.vue'),
  },
  {
    path: '/profile/confirm-psw-biometric',
    name: 'confirm_psw_biometric',
    meta: { style: 'dark', requiresAuth: true },
    component: () => import('../views/Profile/ConfirmPasswordBiometric.vue'),
  },
  {
    path: '/check-email',
    name: 'otp_email',
    meta: { style: 'dark', requiresAuth: true },
    component: () => import('../views/OtpValidator/Email.vue'),
  },
  {
    path: '/profile/edit-phone',
    name: 'edit_phone',
    meta: { style: 'dark', requiresAuth: true },
    component: () => import('../views/Profile/EditPhone.vue'),
  },
  {
    path: '/check-phone',
    name: 'otp_phone',
    meta: { style: 'dark', requiresAuth: true },
    component: () => import('../views/OtpValidator/Phone.vue'),
  },
  {
    path: '/profile/edit-password',
    name: 'edit_password',
    meta: { style: 'dark', requiresAuth: true },
    component: () => import('../views/Profile/EditPassword.vue'),
  },
  {
    path: '/profile/edit-shipping-address',
    name: 'edit_shipping_address',
    meta: { style: 'dark', requiresAuth: true },
    component: () => import('../views/Profile/EditShippingAddress.vue'),
  },

  {
    path: '/support',
    name: 'support',
    meta: { style: 'light', nobkapp: true, requiresAuth: true },
    component: () => import('../views/SupportPage.vue'),
  },
  {
    path: '/pun',
    name: 'pun',
    meta: { style: 'dark', requiresAuth: true },
    component: () => import('../views/PunPage.vue'),
  },
  {
    path: '/helpers/find-door',
    name: 'app-helpers-find-door',
    meta: { style: 'dark', requiresAuth: false },
    component: () => import('../views/App/Helpers/FindDoorPage.vue'),
  },
  {
    path: '/helpers/support',
    name: 'app-helpers-support',
    meta: { style: 'dark', requiresAuth: false },
    component: () => import('../views/App/Helpers/SupportPage.vue'),
  },
  {
    path: '/helpers/offers',
    name: 'app-helpers-offers',
    meta: { style: 'dark', requiresAuth: false },
    component: () => import('../views/App/Helpers/OffersPage.vue'),
  },
  {
    path: '/supplies/:id/graphs/anon',
    name: 'supply_graphs_anon',
    props: true,
    meta: { style: 'dark', requiresAuth: false },
    component: () => import('../views/Supply/AnalyzeGraphPage.vue'),
  }
]

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      const el = window.location.href.split("#")[1];
      if (el.length) {
        document.querySelector('ion-router-outlet').querySelector('ion-content').scrollTo(0, el.offsetTop);
      }
    } else if (savedPosition) {
      return savedPosition;
    }

    function zindex_filter (element) {
      var maxIndex = Array.from(document.querySelectorAll('ion-router-outlet .ion-page'))
          .map(a => parseFloat(window.getComputedStyle(a).zIndex))
          .filter(a => !isNaN(a))
          .sort()
          .pop();
      const style = window.getComputedStyle(element);
      if( style.getPropertyValue('z-index') == maxIndex ) return true;
      else return false;
    }

    setTimeout(()=>{
      let all = document.querySelectorAll('ion-router-outlet .ion-page');
      all = Array.from(all);

      const filtered = all.filter( zindex_filter );
      if(filtered.length > 0 && filtered[0].querySelector('ion-content') ) {
        filtered[0].querySelector('ion-content').scrollToTop();
      }
    }, 50)
  }
});

router.afterEach((v) => {
  if(typeof window.fbq !== 'undefined') {
    window.fbq('track', 'PageView');
  }
  var appEl = document.getElementById('app');
  if(appEl) appEl.classList.value = v.name;
});
