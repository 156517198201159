<template>
  <ion-menu side="end" content-id="main" :swipe-gesture="false">
    <ion-header class="ion-no-border">
      <ion-toolbar class="px-9 mb-20">
        <ion-button slot="end" color="dark" class="button-close m-0" @click="closeMenu">
          <ion-icon slot="icon-only" name="close"/>
        </ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list class="pl-12 pr-9 mb-16 ion-hide-lg-up">
        <ion-item lines="none">
          <router-link :to="{ name: 'dashboard' }" class="fs-5 fw-bold button-menu" v-t="{path:'menu.home'}"/>
        </ion-item>
        <ion-item lines="none">
          <router-link :to="{ name: 'supplies' }" class="fs-5 fw-bold button-menu" v-t="{path:'menu.supplies'}"/>
        </ion-item>
        <ion-item lines="none">
          <router-link :to="{ name: 'messages' }" class="fs-5 fw-bold button-menu" v-t="{path:'menu.messages'}"/>
        </ion-item>
        <ion-item lines="none">
          <router-link :to="{ name: 'profile' }" class="fs-5 fw-bold button-menu" v-t="{path:'menu.profile'}"/>
        </ion-item>
      </ion-list>

      <ion-list class="pl-12 pr-9 mb-16">
        <ion-item lines="none" v-if="isServicesEnabled">
          <router-link :to="{ name: 'services' }" class="fs-6 fw-medium button-menu" v-t="{path:'menu.services'}"/>
        </ion-item>
        <ion-item lines="none" v-if="isPracticesEnabled">
            <router-link :to="{ name: 'practices' }" class="fs-6 fw-medium button-menu" v-t="{path:'menu.practices'}"/>
        </ion-item>
        <ion-item lines="none" v-if="isInvoicesEnabled">
            <router-link :to="{ name: 'invoices', query: { typeCodes: 'all' } }" class="fs-6 fw-medium button-menu" v-t="{path:'menu.invoices'}"/>
        </ion-item>
        <ion-item lines="none">
          <router-link :to="{ name: 'support' }" class="fs-6 fw-medium button-menu" v-t="{path:'menu.support'}"/>
        </ion-item>
      </ion-list>

      <ion-list class="pl-12 pr-9">
        <ion-item lines="none" button @click="logout">
          <span class="fs-6 fw-medium button-menu" v-t="{path:'buttons.logout'}"/>
        </ion-item>
      </ion-list>
    </ion-content>
    <ion-footer class="ion-no-border">
      <div class="pl-12 pr-9 pb-16 fw-medium text-dark" style="font-size: 12px;" v-t="{path:'menu.version', args:{version: version}}"/>
    </ion-footer>
  </ion-menu>
</template>

<script>
import {defineComponent} from 'vue';
import {useRouter} from 'vue-router';
import {
  IonHeader,
  IonContent,
  IonFooter,
  IonButton,
  IonToolbar,
  IonItem,
  IonList,
  IonMenu,
  IonIcon,
  menuController
} from '@ionic/vue';
import {addIcons} from 'ionicons';
import {close} from 'ionicons/icons';
import {useSessionStore} from '@/stores/session'
import p from '/package.json';
import {PushNotifications} from "@capacitor/push-notifications";
import {NativeBiometric} from "@capgo/capacitor-native-biometric";
import {useI18n} from "vue-i18n";

export default defineComponent({
  name: 'HeaderPlatformComponent',
  components: {
    IonHeader,
    IonContent,
    IonFooter,
    IonButton,
    IonToolbar,
    IonIcon,
    IonItem,
    IonList,
    IonMenu,
  },
  setup() {
    const version = p.version;
    const router = useRouter();
    const sessionStore = useSessionStore();
    const {t} = useI18n();

    return {router, version, sessionStore, t};
  },
  created() {
    addIcons({
      'close': close,
    });
  },
  computed: {
    isInvoicesEnabled(){
      const modules = this.sessionStore.module;
      return ( typeof(modules) !== 'undefined' && Array.isArray(modules) && ( modules.some(element => element.key === 'panoram') || modules.some(element => element.key === 'dettFatt') ) && this.router.hasRoute('invoices') );
    },
    isServicesEnabled(){
      const modules = this.sessionStore.module;
      return ( typeof(modules) !== 'undefined' && Array.isArray(modules) && modules.some(element => element.key === 'ser') && this.router.hasRoute('services') );
    },
    isPracticesEnabled(){
      const modules = this.sessionStore.module;
      return ( typeof(modules) !== 'undefined' && Array.isArray(modules) && modules.some(element => element.key === 'prat') && this.router.hasRoute('practices') );
    }
  },
  methods: {
    closeMenu() {
      menuController.close('end');
    },
    logout() {
      localStorage.removeItem('_company');
      localStorage.removeItem('_biometric_active');
      localStorage.removeItem('nb');
      this.sessionStore.logOut().then(() => {
        if(this.$isApp){
          localStorage.removeItem('_company');
          localStorage.removeItem('_biometric_active');
          localStorage.removeItem('nb');
          NativeBiometric.deleteCredentials({
            server: this.t('company.short_name_app'),
          });
          PushNotifications.unregister().then(() => {
            window.location.href = '/login';
          })
        }
        else
        {
          window.location.href = '/login';
        }
      });
    }
  }
})
</script>

<style scoped lang="scss">
ion-list {
  padding: 0;
}

ion-item {
  &::part(native) {
    min-height: auto;
    padding: 0;
  }

  & + ion-item {
    margin-top: 16px;
  }

  span {
    line-height: 26px;
  }
}

.button-menu {
  background: transparent;
  padding-left: 0;
  box-shadow: none;
  text-transform: none;
  color: var(--ion-color-dark);
  text-decoration: none;
  &.router-link-active{
    color: var(--ion-color-primary)
  }
}

ion-button.button-close {
  &::part(native) {
    width: 32px !important;
    height: 32px;
    padding: 5px;
  }
}
</style>
