import {createApp} from 'vue'
import {createI18n} from 'vue-i18n'
import {createGtm} from '@gtm-support/vue-gtm';
import VueShepherdPlugin from 'vue-shepherd';
import 'shepherd.js/dist/css/shepherd.css';
import { App } from '@capacitor/app';
import VueCookies from 'vue3-cookies'
import p from '../package.json';
import Ap from './App.vue';
import {router} from './router';
import * as Sentry from '@sentry/browser';
import { CaptureConsole } from "@sentry/integrations";
import { StatusBar, Style } from '@capacitor/status-bar';
import FooterComponent from '@/components/footers/FooterComponent';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import setupInterceptors from './services/setupInterceptors';
import { pathToRegexp } from 'path-to-regexp';

setupInterceptors();

import {IonicVue, isPlatform, menuController} from '@ionic/vue';

import vueClickOutsideElement from 'vue-click-outside-element'

import 'aos/dist/aos.css'

// Helpers
import helpers from './helpers'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import 'vue-css-file';

import './theme/theme.scss';
import {createPinia} from "pinia";

const pina = createPinia();

/**
 * Load locale dictionary
 * @returns json
 */
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: process.env.VUE_APP_I18N_LOCALE || 'it',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'it',
  messages: loadLocaleMessages(),
});

if(typeof process.env.VUE_APP_SENTRY !== 'undefined')
{
  Sentry.init({
    dsn: ( isPlatform('capacitor') ? 'https://bac18c070ab442e5b43ee287bbcd7711@gt.sgrlucegas.com/4' : process.env.VUE_APP_SENTRY ),
    integrations: [
      new CaptureConsole()
    ],
    debug: false,
    release: p.version,
    autoSessionTracking: false, // se true genera errori 501 not implemented
    environment: process.env.VUE_APP_ENVIRONMENT,
    tracesSampleRate: process.env.VUE_APP_ENVIRONMENT === 'production' ? 0.5 : 1,
    tracingOptions: {
      trackComponents: true,
    },
    logErrors: process.env.VUE_APP_ENVIRONMENT === 'production' ? false : true,
    attachProps: true,
    attachStacktrace: true,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
  });
}

const app = createApp(Ap)
  .use(IonicVue, {
    rippleEffect: false,
    mode: 'md',
    animated: !helpers.isWeb(),
  })
  .use(router)
  .use(pina)
  .use(i18n)
  .use(VueCookies)
  .use(VueShepherdPlugin);

app.use(vueClickOutsideElement);
if(typeof process.env.VUE_APP_GTM !== 'undefined') {
  app.use(
      createGtm({
        id: process.env.VUE_APP_GTM,
        defer: true,
        compatibility: false,
        nonce: '2726c7f26c',
        enabled: true,
        debug: true,
        loadScript: true,
        vueRouter: router,
        ignoredViews: [],
        trackOnNextTick: false,
      }),
  );
}
app.component('footer-component', FooterComponent);

app.config.globalProperties.$helpers = helpers;
app.config.globalProperties.$isApp = isPlatform('capacitor');

app.config.globalProperties.$requestPermissionsNotification = function (result) {
  if (result.receive === 'granted') {
    PushNotifications.register();
  }
};

app.config.globalProperties.$registrationNotification = function (token) {
  sessionStore.devices(token.value).then((res) => {
    if(res.success)
    {
      localStorage.setItem('pushNotificationsFbPushId', token.value);
      sessionStore.setUserNotificationPush(true, true);
    }
  });
};

app.config.globalProperties.$registrationError = function () {
  sessionStore.setUserNotificationPush(false, false);
};

const routesInvoiceList = [
  {
    path: '/invoices',
    name: 'invoices',
    meta: { style: 'dark', nobkapp: true, requiresAuth: true },
    component: () => import('./views/Invoice/InvoicesPage.vue'),
  },
  {
    path: '/invoices-list',
    name: 'invoices_list',
    meta: { style: 'dark', requiresAuth: true },
    component: () => import('./views/Invoice/InvoicesListPage.vue'),
  },
];
const routesInvoiceDetails = [
  {
    path: '/invoice/:id',
    name: 'invoice',
    meta: { style: 'dark', requiresAuth: true },
    component: () => import('./views/Invoice/InvoicePage.vue'),
  },
  {
    path: '/invoice/:id/request-installments/:contractKey/:key',
    name: 'invoice_request_installments',
    meta: { style: 'dark', requiresAuth: true },
    component: () => import('./views/Invoice/RequestInstallmentsPage.vue'),
  },
  {
    path: '/invoice/:id/comparation',
    name: 'invoice_comparation_list',
    meta: { style: 'dark', requiresAuth: true },
    component: () => import('./views/Invoice/ComparationListPage.vue'),
  },
  {
    path: '/invoice/:id1/comparation/:id2',
    name: 'invoice_comparation',
    meta: { style: 'dark', requiresAuth: true },
    component: () => import('./views/Invoice/ComparationPage.vue'),
  },
];
const routesInvoicePayment = [
  {
    path: '/invoice/:id/installments-to-pay',
    name: 'invoice_installments_to_pay',
    meta: { style: 'dark', requiresAuth: true },
    component: () => import('./views/Invoice/InstallmentsToPayPage.vue'),
  },
  {
    path: '/invoice/:id/payment_ok',
    name: 'invoice_payment_ok',
    meta: { style: 'dark', requiresAuth: true, fullScreenApp: true },
    component: () => import('./views/Invoice/PaymentOKPage.vue'),
  },
  {
    path: '/invoice/:id/payment_ko',
    name: 'invoice_payment_ko',
    meta: { style: 'dark', requiresAuth: true, fullScreenApp: true },
    component: () => import('./views/Invoice/PaymentKOPage.vue'),
  },
];
const routesSupplyGraphs = [
  {
    path: '/supplies/:id/graphs',
    name: 'supply_graphs',
    props: true,
    meta: { style: 'dark', requiresAuth: true },
    component: () => import('./views/Supply/AnalyzeGraphPage.vue'),
  }
];
const routesPractice = [
  {
    path: '/practices',
    name: 'practices',
    meta: { style: 'light', nobkapp: true, requiresAuth: true },
    component: () => import('./views/PracticesPage.vue'),
  },
  {
    path: '/practices/:id',
    name: 'practice',
    meta: { style: 'light', requiresAuth: true },
    component: () => import('./views/PracticePage.vue'),
  }
];
const routesMaintenance = [
  {
    path: '/services',
    name: 'services',
    meta: { style: 'light', nobkapp: true, requiresAuth: true },
    component: () => import('./views/Service/ServicesPage.vue'),
  }, {
    path: '/maintenance/:index',
    name: 'maintenance',
    meta: { style: 'dark', requiresAuth: true },
    component: () => import('./views/Maintenance/MaintenancePage.vue'),
  }
];
const routesSelfReadingList = [
  {
    path: '/self-reading/:id/archive',
    name: 'supply_self_reading_archive',
    meta: { style: 'dark', requiresAuth: true },
    component: () => import('./views/SelfReading/ArchivePage.vue'),
  },
  {
    path: '/self-reading/archive',
    name: 'self_reading_archive',
    meta: { style: 'dark', requiresAuth: true },
    component: () => import('./views/SelfReading/ArchivePage.vue'),
  }
];
const routesSelfReadingAdd = [
  {
    path: '/supplies/:id/autolettura',
    name: 'supply_self_reading',
    meta: { style: 'dark', requiresAuth: null },
    component: () => import('./views/Supply/SelfReadingPage.vue'),
  }
];

const modulesEnabled = ['AutoLettura', 'letture', 'prat', 'ser', 'pay', 'panoram', 'dettFatt', 'grafConsumi'];

var routesAvailables = [];
routesAvailables['AutoLettura'] = routesSelfReadingAdd;
routesAvailables['letture'] = routesSelfReadingList;
routesAvailables['prat'] = routesPractice;
routesAvailables['ser'] = routesMaintenance;
routesAvailables['pay'] = routesInvoicePayment;
routesAvailables['panoram'] = routesInvoiceDetails;
routesAvailables['dettFatt'] = routesInvoiceDetails;
routesAvailables['grafConsumi'] = routesSupplyGraphs;
routesAvailables['fatt'] = routesInvoiceList;

app.config.globalProperties.$routesAvailables = routesAvailables;
app.config.globalProperties.$modulesEnabled = modulesEnabled;

import {useSessionStore} from '@/stores/session'
import {useContractsStore} from '@/stores/contracts'
import {PushNotifications} from "@capacitor/push-notifications";
const sessionStore = useSessionStore();

// Verifico se nel URL è presente il campo rcrm = 1
// Se si significa che sta accedendo tramite CRM quindi devo nascondere tutti i route tranne quello dei grafici consumi
const urlParams = new URLSearchParams(window.location.search);
const isReferCrmParam = urlParams.get('rcrm');
if(isReferCrmParam == '1' || localStorage.getItem('_referByCrm') === '1')
{
  localStorage.setItem('_referByCrm', '1')
  routesAvailables = [];
  routesAvailables['grafConsumi'] = routesSupplyGraphs;
}

if(typeof process.env.VUE_APP_COMPANY != 'undefined')
  sessionStore.setCompany(process.env.VUE_APP_COMPANY);

const contractsStore = useContractsStore();
router.isReady().then(() => {
  var company = sessionStore.getCompany;
  var isLoggedIn = sessionStore.isLoggedIn;
  if(!isLoggedIn && company && app.config.globalProperties.$isApp){
    if(localStorage.getItem('_biometric_active') != '1')
    {
      localStorage.removeItem('_company');
      window.location.href = '/login';
    }
  }else if(app.config.globalProperties.$isApp && company){
    sessionStore.setCompany(company);
  }

  if(app.config.globalProperties.$isApp) {
    FirebaseAnalytics.setCollectionEnabled({
      enabled: true,
    });
  }

  app.mount('#app');
  if(typeof company != 'undefined' && company)
  {
    if(company.toUpperCase() == 'SGR')
      document.title += ' SGR Luce & Gas';
    else if(company.toUpperCase() == 'AST')
      document.title += ' Astea Energia';

    document.body.classList.add(company.toLowerCase());
  }

});
router.beforeEach(async (to) => {
  const res = await sessionStore.modules();
  if (res.success) {
    var routeAdded = null;
    res.data.forEach((el) =>{
      if(modulesEnabled.includes(el.key))
      {
        if(typeof routesAvailables[el.key] == 'object')
        {
          routesAvailables[el.key].forEach((val) => {
            if(!router.hasRoute(val.name))
            {
              router.addRoute(val);
              var regex = pathToRegexp(val.path);
              if(!routeAdded)
                routeAdded = regex.exec(to.fullPath)
            }
          });
        }
      }
    });

    if(routeAdded !== null)
      return to.fullPath;

  }

  if(!router.hasRoute(to.name))
  {
    return {
      name: 'error-404',
      replace: true
    }
  }

  if(app.config.globalProperties.$isApp)
  {
    FirebaseAnalytics.setScreenName({ screenName: to.name });

    StatusBar.setOverlaysWebView({ overlay: false });
    if(to.meta.style == 'light')
      StatusBar.setStyle({ style: Style.Light });
    else
      StatusBar.setStyle({ style: Style.Dark });
  }
  if(to.name !== 'company' && !sessionStore.getCompany && !to.meta.noCompanyRequired) {
    return {
      name: 'company',
      replace: true
    }
  }
  // redirect al login dell'app se sei connesso da app
  if(to.name === 'login' && app.config.globalProperties.$isApp && sessionStore.getCompany){
    return {
      name: 'app-login',
      replace: true
    }
  }
  // Redirect al login se è una sezione protetta da login ma non si è loggati
  if (to.meta.requiresAuth && !sessionStore.isLoggedIn) {
    return {
      name: 'login',
      replace: true
    }
  }
  // Consenti se accesso da push
  else if (to.query.isGuest && sessionStore.isLoggedIn) {
    return true;
  }
  else if(sessionStorage.getItem('linked') && sessionStore.isLoggedIn)
  {
    sessionStorage.removeItem('linked');
  }
  // Primo avvio da app loggato con accesso veloce
  else if (sessionStore.isLoggedIn && app.config.globalProperties.$isApp && ( to.name != 'app-login' && to.name != 'active_biometric' ) && sessionStorage.getItem('logged') != '1' ) {
    return {
      name: 'app-login',
      replace: true
    }
  }
  // Redirect alla dashboard se è una sezione Non protetta da login ma si è loggati
  else if (to.meta.requiresAuth === false && sessionStore.isLoggedIn && sessionStorage.getItem('logged') == '1') {
    return {
      name: 'dashboard',
      replace: true
    }
  }
  // Ricerca del customer nel caso si fosse loggati e si avesse il customerId in sessione ma non l'oggetto customer
  else if (to.meta.requiresAuth && sessionStore.isLoggedIn) {
    if (contractsStore.getCustomerId && !contractsStore.getCurrentCustomer) {
      await contractsStore.getCustomer(contractsStore.getCustomerId).then((res) => {
        if (res.success) {
          contractsStore.setCustomer(res.data)
          return true;
        } else {
          return {
            name: 'login',
            replace: true
          }
        }
      })
    }
    // Redirect alla pagina dei customer nel caso non ci fosse il customerId in memoria
    else if (!contractsStore.getCustomerId && to.name !== 'customers' && to.name !== 'onboarding' && to.name !== 'request_delegation' && to.name !== 'active_biometric') {
      return {
        name: 'customers',
        replace: true
      }
    }
  }
  contractsStore.showGraph = false;
  contractsStore.showLegend = false;
  sessionStore.clearFeedbackData();
  menuController.close('end');
})
router.afterEach(async () => {
  sessionStore.setLoading(false);
});

App.addListener('appStateChange', ({ isActive }) => {
  if(sessionStore.isLoggedIn)
  {
    if(isActive && isPlatform('capacitor')){
      sessionStore.getSession(true).then((res) => {
        if (!res.success) {
          if(localStorage.getItem('_biometric_active') == '1')
          {
            sessionStore.logOutPartial().finally(() => {
              sessionStore.setFeedbackData({
                isFull: true,
                title: 'La tua sessione è scaduta',
                description: "Non hai eseguito alcuna operazione negli ultimi minuti, quindi per ragioni di sicurezza la tua sessione è scaduta. Effettua nuovamente l'accesso",
                firstButton: {label: "Vai al login", link: '/login'},
              })
            });
          }
          else
          {
            sessionStore.logOutPartial(true).finally(() => {
              sessionStore.setFeedbackData({
                isFull: true,
                title: 'La tua sessione è scaduta',
                description: "Non hai eseguito alcuna operazione negli ultimi minuti, quindi per ragioni di sicurezza la tua sessione è scaduta. Effettua nuovamente l'accesso",
                firstButton: {label: "Vai al login", link: '/login'},
              })
            });
          }
        }
      })
    }
  }
});

App.addListener('appUrlOpen', data => {
  const slug = data.url.split('.com').pop();
  if (slug) {
    router.push({
      path: slug,
    });
  }
});