import {defineStore} from 'pinia'
import axios from "axios"
import moment from "moment"
import {alertController} from "@ionic/vue";
import _cloneDeep from 'lodash/cloneDeep';
import uninterceptedAxiosInstance from "@/services/setupUninterceptors";


export const useNotificationsStore = defineStore('notifications', {
  state: () => {
    return {
      notifications: [],
    }
  },
  getters: {
    notRead: (state) => {
      const items = state.notifications.filter((notification) =>
        typeof notification.readTime === "undefined" || notification.readTime === null  || !notification.readTime.length);
      return items.length;
    },
    hasRequired: (state) => state.notifications.some((notification) =>
      notification.notificationType.acceptanceRequired && (!notification.acceptedTime || !notification.acceptedTime.length)),
  },
  actions: {
    async getAllNotifications() {
      const params = {
        activeOnly: true
      };

      try {
        this.notifications = [];
        const {data} = await uninterceptedAxiosInstance.get('/services/rest/portalapi/Core/v1/notifications', {params});
        if (typeof data.findNotificationsResponse !== "undefined")
        {
          if (typeof data.findNotificationsResponse.notifications.notification !== "undefined")
            this.notifications = data.findNotificationsResponse.notifications.notification;

          if (!Array.isArray(this.notifications)) this.notifications = [this.notifications];

          this.notifications.forEach((notification) => {
            if (notification.notificationType.acceptanceRequired && (!notification.acceptedTime || !notification.acceptedTime.length)) {
              this.acceptanceRequired(notification)
            }
          })
        }


        return {success: true, data: this.notifications};
      } catch (error) {
        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },
    async getNotifications(pagingTake = 1000, pagingSkip = 0) {
      const params = {
        pagingTake,
        pagingSkip,
        activeOnly: true
      };
      try {
        let notifications = [];
        const {data} = await axios.get('/services/rest/portalapi/Core/v1/notifications', {params});
        if (typeof data.findNotificationsResponse.notifications.notification !== "undefined")
          notifications = data.findNotificationsResponse.notifications.notification;

        if (!Array.isArray(notifications)) notifications = [notifications];

        return {success: true, data: notifications};
      } catch (error) {
        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async getNotification(id) {
      try {
        let notification = null;
        const {data} = await axios.get('/services/rest/portalapi/Core/v1/notifications/' + id);
        if (typeof data.getNotificationResponse.notification !== "undefined")
          notification = data.getNotificationResponse.notification;

        return {success: true, data: notification};
      } catch (error) {
        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async readingNotification(id, reloadAll = true) {
      try {
        let params = {
          updateNotificationRequest:{
            notification: {
              id: Number(id),
              readingDate: moment()
            }
          }
        };
        const {data} = await axios.put('/services/rest/portalapi/Core/v1/notifications', params);
        if(reloadAll) this.getAllNotifications();
        return {success: true, data: data};
      } catch (error) {
        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async readingNotificationPush(id, mn_msg_id, pushId) {
      try {
        let params = {
          updateNotificationRequest:{
            notification: {
              id: Number(id),
              mn_msg_id: mn_msg_id,
              pushId: pushId,
              readingDate: moment()
            }
          }
        };
        const {data} = await uninterceptedAxiosInstance.put('/services/rest/portalapi/Core/v1/notifications', params);
        this.getAllNotifications();
        return {success: true, data: data};
      } catch (error) {
        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async confirmNotification(id) {
      try {
        let params = {
          updateNotificationRequest:{
            notification: {
              id: Number(id),
              confirmationDate: moment()
            }
          }
        };
        const {data} = await uninterceptedAxiosInstance.put('/services/rest/portalapi/Core/v1/notifications', params);
        return {success: true, data: data};
      } catch (error) {
        return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
      }
    },

    async acceptanceRequired(notification){
      const self = this;
      const alert = await alertController.create({
        message: '<h3 class="title">' + notification.notificationType.description + '</h3> ' +
          '<div class="message">' + notification.notificationType.detail + '</div>',
        buttons: [
          {
            text: 'HO PRESO VISIONE DEL MESSAGGIO',
            role: 'confirm',
            handler: () => {
              self.confirmNotification(notification.id);
              self.notifications.map((n) => {
                const not = _cloneDeep(n);
                if(n.id === notification.id) not.acceptedTime = moment();
                return not;
              })
            }
          }
        ],
        cssClass: 'alertPopup',
        backdropDismiss: false,
      });
      await alert.present();
    },
  },
})
